import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { homePageTheme } from 'components/themes/theme';
import { horizontalPaddingInPx, maxPageWidthInPxIncludingPadding } from 'utils/lookAndFeel';
import { FiltersJsxWrapperProps } from './shared';

export function FiltersSection({ filtersFormJsx, filtersChipPanelJsx }: FiltersJsxWrapperProps) {
    const isBelowLg = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

    return (
        <Box
            component='section'
            sx={{
                backgroundColor: '#FFFFFF',
                maxWidth: '100%',
                width: '100%',
                margin: '0 auto',
                marginBottom: isBelowLg ? '14px' : '36px',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
            }}>
            <Box
                sx={{
                    padding: isBelowLg
                        ? `24px 14px 0px 14px`
                        : `24px ${horizontalPaddingInPx}px 0px ${horizontalPaddingInPx}px`,
                    width: '100%',
                    maxWidth: maxPageWidthInPxIncludingPadding,
                }}>
                <ThemeProvider theme={homePageTheme}>
                    <Grid sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
                        {filtersFormJsx}
                        <Box sx={{ width: isBelowLg ? 'calc(100% - 20px)' : 'calc(100% - 171px)' }}>
                            {filtersChipPanelJsx}
                        </Box>
                    </Grid>
                </ThemeProvider>
            </Box>
        </Box>
    );
}
