import { ETFMenu, ETFMenuProps } from './ETFMenu';

export function MenuVariant2(props: ETFMenuProps) {
    return (
        <ETFMenu
            {...props}
            menuItemStyles={{
                padding: '10px 21px 12px 21px',
                borderLeft: '0px',
                ':first-of-type': {
                    borderBottom: 'solid #ccc 1px',
                    paddingBottom: '16px',
                    paddingTop: '21px',
                    color: '#007BB8',
                },
                ':nth-of-type(2)': {
                    paddingTop: '23px',
                },
                ...props.menuItemStyles,
            }}
            onHoverItemStyles={{
                paddingLeft: '20px',
            }}
            addBorderBetweenItems={false}
            useSelectedIndex={false}
            paperProps={{
                borderRadius: '10px',
                marginTop: '5px',
            }}
        />
    );
}
