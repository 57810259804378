import { ThemeOptions } from '@mui/material';
import { fontFamilies } from '../../utils';
import { deepmerge } from '@mui/utils';
import { scrollbarThemeV3 } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';

export const menuItemTextStyle1 = {
    fontSize: '13.5px',
    fontFamily: fontFamilies.GraphikMedium,
    color: '#0B2958',
};

export const menuItemTextStyle2 = {
    fontSize: '12px',
    fontFamily: fontFamilies.GraphikMedium,
    color: '#222222',
};

export const accordionBlueLinks: ThemeOptions = {
    components: {
        MuiMenu: {
            styleOverrides: {
                root: {
                    zIndex: 3000,
                },
                paper: {
                    borderRadius: '8px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    ...scrollbarThemeV3,
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: '12.5px 0px',
                    '& > .MuiDivider-root': {
                        border: '0px',
                    },
                    '& > .MuiListItem-root': {
                        '& > .MuiButtonBase-root': {
                            padding: '12.5px 18px',
                            '& > .MuiListItemIcon-root': {
                                minWidth: 'unset',
                                '& > .MuiAvatar-root': {
                                    marginRight: '15px',
                                },
                            },
                            '& > .MuiListItemText-root': {
                                margin: '0px',
                                marginTop: '3px',
                                '& > .MuiTypography-root': {
                                    ...menuItemTextStyle1,
                                },
                            },
                        },
                    },
                    '& > .MuiAccordion-root': {
                        boxShadow: 'none',
                        '& > .MuiAccordionSummary-root': {
                            padding: '12.5px 18px',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            },
                            '& > .MuiAccordionSummary-content': {
                                margin: '0px',
                                '& > .MuiListItemIcon-root': {
                                    alignItems: 'center',
                                    minWidth: 'unset',
                                    '& > .MuiAvatar-root': {
                                        marginRight: '15px',
                                    },
                                },
                                '& > .MuiTypography-root': {
                                    marginTop: '3px',
                                    marginRight: '15px',
                                    ...menuItemTextStyle1,
                                },
                            },
                            '& > .MuiAccordionSummary-expandIconWrapper': {
                                '&.Mui-expanded': {
                                    transform: 'rotate(90deg)',
                                },
                            },
                        },
                        '& .MuiAccordionDetails-root': {
                            padding: '0px',
                            '& > .MuiDivider-root': {
                                border: '0px',
                            },
                            '& .MuiListItemButton-root': {
                                padding: '10px 18px 10px 49px',
                                '& > .MuiListItemText-root': {
                                    margin: '0px',
                                    '& > .MuiTypography-root': {
                                        color: '#007AB9',
                                        fontSize: '13.5px',
                                        fontFamily: fontFamilies.GraphikRegular,
                                    },
                                },
                            },
                        },
                        '&:before': {
                            display: 'none',
                        },
                    },
                },
            },
        },
    },
};

export const accordionBlackBorder: ThemeOptions = {
    components: {
        MuiMenu: {
            styleOverrides: {
                root: {
                    zIndex: 3000,
                },
                paper: {
                    borderRadius: '8px',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                    ...scrollbarThemeV3,
                },
            },
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: '14.5px 0px',
                    '& > .MuiDivider-root': {
                        border: '0px',
                    },
                    '& > .MuiListItem-root': {
                        '& > .MuiButtonBase-root': {
                            padding: '14.5px 25px 14.5px 25px',
                            '& > .MuiListItemIcon-root': {
                                minWidth: 'unset',
                                '& > .MuiAvatar-root': {
                                    marginRight: '18px',
                                },
                            },
                            '& > .MuiListItemText-root': {
                                margin: '0px',
                                marginTop: '3px',
                                '& > .MuiTypography-root': {
                                    ...menuItemTextStyle2,
                                },
                            },
                        },
                    },
                    '& > .MuiAccordion-root': {
                        boxShadow: 'none',
                        '& > .MuiAccordionSummary-root': {
                            padding: '14.5px 25px 14.5px 25px',
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.04)',
                            },
                            '& > .MuiAccordionSummary-content': {
                                margin: '0px',
                                '& > .MuiListItemIcon-root': {
                                    alignItems: 'center',
                                    minWidth: 'unset',
                                    '& > .MuiAvatar-root': {
                                        marginRight: '18px',
                                    },
                                },
                                '& > .MuiTypography-root': {
                                    marginTop: '3px',
                                    marginRight: '18px',
                                    ...menuItemTextStyle2,
                                },
                            },
                            '& > .MuiAccordionSummary-expandIconWrapper': {
                                transform: 'rotate(90deg)',
                                '&.Mui-expanded': {
                                    transform: 'rotate(270deg)',
                                },
                            },
                        },
                        '& .MuiAccordionDetails-root': {
                            padding: '0px',
                            '& > .MuiDivider-root': {
                                border: '0px',
                            },
                            '& .MuiListItemButton-root': {
                                padding: '0px 25px 0px 25px',
                                '& > .MuiListItemText-root': {
                                    margin: '0px 0px 0px 8px',
                                    borderLeft: '1px dotted #CCCCCC',
                                    padding: '10px 0px 10px 26px',
                                    '& > .MuiTypography-root': {
                                        color: '#555555',
                                        fontSize: '12px',
                                        fontFamily: fontFamilies.GraphikMedium,
                                    },
                                },
                            },
                        },
                        '&:before': {
                            display: 'none',
                        },
                    },
                },
            },
        },
    },
};
