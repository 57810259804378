import DividerStyle1 from '@cfra-nextgen-frontend/shared/src/components/Divider/StyledDividers';
import {
    TypographyStyle15,
    TypographyStyle2,
    TypographyStyle3,
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { Box, createTheme, SxProps, ThemeProvider } from '@mui/material';

type SearchResultsHeaderProps = {
    title?: string;
    subTitle?: string;
    containerStyles?: SxProps;
    slot0?: JSX.Element | boolean;
    slot1?: JSX.Element;
    slot1Divider?: boolean;
    slot2?: JSX.Element;
    slot2Prefix?: string;
    slot2Divider?: boolean;
    slot3?: JSX.Element;
};

const Divider = <DividerStyle1 sx={{ height: '21px', marginLeft: '12px', marginRight: '7px' }} />;

export function CardHeaderVariant1(props: SearchResultsHeaderProps) {
    const {
        title,
        subTitle,
        containerStyles,
        slot0,
        slot1,
        slot1Divider = true,
        slot2,
        slot2Prefix = 'Sort:',
        slot2Divider = true,
        slot3,
    } = props;

    return (
        <ThemeProvider theme={createTheme()}>
            <Box
                key='searchResultsHeader'
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '46px',
                    boxSizing: 'content-box',
                    alignItems: 'center',
                    paddingBottom: '8px',
                    justifyContent: 'space-between',
                    ...containerStyles,
                }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }} gap='8px'>
                    {title && <TypographyStyle2>{title}</TypographyStyle2>}
                    {title && subTitle && <DividerStyle1 />}
                    {subTitle && <TypographyStyle3>{subTitle}</TypographyStyle3>}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {slot0}
                    {slot1 && (
                        <>
                            <Box sx={{ margin: '2px 0px 4px 6px' }}>{slot1}</Box>
                            {slot1Divider && Divider}
                        </>
                    )}
                    {slot2 && (
                        <>
                            {slot2Prefix && (
                                <TypographyStyle15 sx={{ letterSpacing: 'normal' }}>{slot2Prefix}</TypographyStyle15>
                            )}
                            {slot2}
                            {slot2Divider && Divider}
                        </>
                    )}
                    {slot3}
                </Box>
            </Box>
        </ThemeProvider>
    );
}
