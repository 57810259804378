import ChainIcon from '@cfra-nextgen-frontend/shared/src/assets/images/ChainIcon.svg';
import { StyledLink, StyledTitle } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/styledComponents';
import { Box, SxProps } from '@mui/material';
import { CSSProperties } from 'react';
import { To } from 'react-router-dom';

export type TitleWithLinkProps = {
    linkProps: {
        linkText: string;
        onClick?: () => void;
        state?: any;
        to: To;
    };
    title: string;
    titleSx?: SxProps;
    headerAndLinkStyles?: SxProps;
    linkTextStyles?: SxProps;
    titleIconStyles?: CSSProperties;
};

export function TitleWithLink({
    linkProps,
    title,
    titleSx,
    linkTextStyles,
    titleIconStyles,
    headerAndLinkStyles,
}: TitleWithLinkProps) {
    const { linkText, ...restLinkProps } = linkProps;

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '45px',
                ...headerAndLinkStyles,
            }}>
            <StyledTitle sx={{ paddingLeft: '20px', ...titleSx }}>{title}</StyledTitle>
            {linkText && (
                <Box sx={{ paddingRight: '40px', ...linkTextStyles }}>
                    <img
                        src={ChainIcon}
                        alt='Chain Icon'
                        style={{
                            width: '20px',
                            height: '10px',
                            marginRight: '9px',
                            ...titleIconStyles,
                        }}
                    />
                    <StyledLink {...restLinkProps}>{linkText}</StyledLink>
                </Box>
            )}
        </Box>
    );
}
