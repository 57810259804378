import { Item } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { Box, createTheme, Theme, ThemeProvider } from '@mui/material';
import { useMemo, Children } from 'react';
import { Controller } from 'react-hook-form';
import { fontFamilies } from '../../utils';
import { CheckboxAdvanced } from '../ETFButton/CheckboxAdvanced';
import { CommonFormComponentProps } from './types/form';

const defaultTheme: Theme = createTheme();

type FormCheckboxListProps = {
    options: Array<Item>;
    theme?: Theme;
    renderOptions?: boolean;
} & CommonFormComponentProps;

export default function FormCheckboxList(props: FormCheckboxListProps) {
    const defaultValue = (props.getValues?.(props.name) || []) as Array<Item>;
    const theme = useMemo(() => props.theme || defaultTheme, [props.theme]);

    return (
        <Controller
            name={props.name}
            defaultValue={defaultValue}
            control={props.control}
            render={({ field }) => {
                const previousKeys = (field.value as Array<Item>).map((item) => item.key);

                const completeOnChange = (option: Item, checked: boolean) => {
                    let resultKeys: Array<number> = [];

                    if (previousKeys.includes(option.key) && !checked) {
                        resultKeys = previousKeys.filter((key) => key !== option.key);
                    }

                    if (!previousKeys.includes(option.key) && checked) {
                        resultKeys = [...previousKeys, option.key];
                    }

                    const resultOptions = props.options.filter((option) => {
                        return resultKeys.includes(option.key);
                    });

                    field.onChange(resultOptions);
                    props.submitHandler?.(resultOptions);
                };

                return (
                    <ThemeProvider theme={theme}>
                        {Children.toArray(
                            props.options.map((option) => {
                                return (
                                    <>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                width: '100%',
                                                height: '30px',
                                                alignItems: 'center',
                                            }}>
                                            <CheckboxAdvanced
                                                onChange={(_, checked: boolean) => {
                                                    completeOnChange(option, checked);
                                                }}
                                                checked={previousKeys.includes(option.key)}
                                            />
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    width: '100%',
                                                    paddingLeft: '14px',
                                                    fontSize: '13.5px',
                                                    fontFamily: fontFamilies.GraphikRegular,
                                                    color: '#333333',
                                                    paddingTop: '3px',
                                                    lineHeight: 1
                                                }}>
                                                <Box>{option.value}</Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    paddingLeft: '8px',
                                                }}>{option.count}</Box>
                                            </Box>
                                        </Box>
                                    </>
                                );
                            }),
                        )}
                    </ThemeProvider>
                );
            }}
        />
    );
}
