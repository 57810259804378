import { AgGridProps, defaultMinWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGrid';
import { AgGridCard } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCard';
import { defaultMaxWidth } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridCardInfiniteCSM';
import '@cfra-nextgen-frontend/shared/src/components/AgGrid/scss/GridThemeV2.scss';
import '@cfra-nextgen-frontend/shared/src/components/AgGrid/scss/HideVerticalScroll.scss';
import {
    AgGridThemes,
    getColumnDefsEqualComparator,
    handleSortIndicatorClick,
    handleSortOrderIndicators,
    keepNoLeftPaddingOnMove,
} from '@cfra-nextgen-frontend/shared/src/components/AgGrid/utils';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { SendSingleRequestConfig } from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';
import { ScreenerResearchData, Viewdata } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { extractFromScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/columnDefs';
import { getRowID } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/ssr';
import { getUserColumnDefs } from '@cfra-nextgen-frontend/shared/src/components/UserPreferences/utils/agGrid';
import { useMakeIndependent } from '@cfra-nextgen-frontend/shared/src/hooks/useMakeIndependent';
import { useMediaDataTransformer } from '@cfra-nextgen-frontend/shared/src/hooks/useMediaDataTransformer';
import { useUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import {
    AgGridPreferencesEachElement,
    GeneralPreferencesConfiguration,
    PreferenceType,
} from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ModelUpdatedEvent, SortChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { getCellRendererValueProcessor } from 'components/AgGrid/renderers';
import { debounce } from 'lodash';
import { Dispatch, RefObject, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { UseQueryResult } from 'react-query';

const gridTheme = [AgGridThemes.GridThemeV2, 'ag-panel-top-position-fixed'];

// regular ag grid with infinite scroll and stable card width
// used in the ResearchComponent on the mobile screens
export function SearchResultsTableViewSSR({
    searchByParams: searchByParamsProp,
    config,
    autoSizePadding,
    tooltipShowDelay,
    updateSearchByParams,
    setResultsCountRef,
    resetColumnsCallback,
    preferencesConfiguration,
    sortOnReset,
}: {
    searchByParams: SearchByParams;
    config: SendSingleRequestConfig;
    autoSizePadding?: number;
    tooltipShowDelay?: number;
    updateSearchByParams: Dispatch<SearchByParams>;
    setResultsCountRef: RefObject<Dispatch<number>>;
    resetColumnsCallback: () => void;
    preferencesConfiguration?: GeneralPreferencesConfiguration;
    sortOnReset: AgGridProps['sortOnReset'];
}) {
    const gridRef = useRef<AgGridReact>(null);

    const { sendSingleRequest, getDataSource } = useContext(ProjectSpecificResourcesContext);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is required for SearchResultsTableViewSSR');
    }

    if (!getDataSource) {
        throw new Error('getDataSource is required for SearchResultsTableViewSSR');
    }

    const searchByParams = useMemo(
        () => ({
            // include from, includeData, includeMetadata for both resultQuery and dataSource to have same queryKey
            // and so share the same cache
            from: 0,
            includeData: undefined,
            includeMetadata: undefined,
            ...searchByParamsProp,
        }),
        [searchByParamsProp],
    );

    // useSsrDataSource is not working well in this case, there is an issue with useInfiniteQuery
    const resultQuery = sendSingleRequest?.(searchByParams, config) as UseQueryResult<ScreenerResearchData>;

    useEffect(() => {
        const total = resultQuery?.data?.results?.total;

        setResultsCountRef.current?.(typeof total === 'number' ? total : -1);
    }, [resultQuery.data, setResultsCountRef]);

    const mediaTransformedViewData = useMediaDataTransformer<Viewdata>({ inputData: resultQuery.data?._viewdata });

    const screenerData: ScreenerResearchData | undefined = useMemo(
        () =>
            resultQuery.data
                ? { ...resultQuery.data, ...(mediaTransformedViewData ? { _viewdata: mediaTransformedViewData } : {}) }
                : undefined,
        [resultQuery.data, mediaTransformedViewData],
    );

    const { getUserPreferences, setUserPreferences } = useUserPreferences(
        preferencesConfiguration?.useUserPreferencesProps,
    );

    const { minWidths, customFlexibleColumns, columnDefs }: ReturnType<typeof extractFromScreenerData> = useMemo(() => {
        // add !mediaTransformedViewData here to avoid jumping of the grid header on click on sort icon
        if (!resultQuery.data || !screenerData || !mediaTransformedViewData) {
            return { minWidths: {}, customFlexibleColumns: [], columnDefs: [] };
        }

        const result = extractFromScreenerData({
            screenerData,
            cardName: 'Search Results',
            outerGetCellRendererValueProcessor: getCellRendererValueProcessor,
            keepNoLeftPadding: true,
        });

        if (!preferencesConfiguration) {
            return result;
        }

        const userPreferences = getUserPreferences?.<AgGridPreferencesEachElement>({
            preferenceType: PreferenceType.AgGridPreferences,
            selector: preferencesConfiguration?.selectorConfiguration.selector,
        });

        if (!userPreferences) {
            return result;
        }

        return {
            ...result,
            columnDefs: getUserColumnDefs({
                initialColumnDefs: result.columnDefs,
                userPreferences,
                userPreferencesToApply: {
                    columnsWidths: true,
                    // in this component we should handle sorting without using columnDefs
                    columnSorting: false,
                    columnsOrder: true,
                    columnsVisibility: true,
                },
            }),
        };
    }, [resultQuery?.data, screenerData, mediaTransformedViewData, getUserPreferences, preferencesConfiguration]);

    const getResizableMinWidthForColumn = useCallback(
        (headerName: string) =>
            headerName === 'undefined' ? defaultMinWidth : minWidths[headerName] || defaultMinWidth,
        [minWidths],
    );

    const onModelUpdatedCallback = useMemo(() => {
        return debounce((event: ModelUpdatedEvent) => {
            // handle ag grid columns sorting icon (only icon, the sorting logic supposed to be handled on backend side)
            handleSortOrderIndicators({
                event,
                sortDirection: searchByParams.sortDirection,
                orderBy: searchByParams.orderBy,
            });
        }, 200);
    }, [searchByParams.orderBy, searchByParams.sortDirection]);

    useEffect(() => {
        return () => {
            onModelUpdatedCallback.cancel();
        };
    }, [onModelUpdatedCallback]);

    const { independentValue: onModelUpdatedRef } = useMakeIndependent({
        valueGetter: () => onModelUpdatedCallback,
        defaultValue: (() => {}) as any,
    });

    const { independentValue: onSortChangedRef } = useMakeIndependent<(event: SortChangedEvent) => void>({
        valueGetter: () => (event: SortChangedEvent) => {
            handleSortIndicatorClick({
                event,
                updateSearchByParams,
            });
        },
        defaultValue: () => {},
    });

    const dataSource = useMemo(() => {
        return getDataSource({
            metadataFields: resultQuery?.data?._metadata.fields || [],
            etfData: resultQuery?.data?.results.research || [],
            requestParams: searchByParams,
            _resultsKey: 'research',
            defaultFrom: 0,
            size: searchByParams.size,
            queryKeyFirstElement: config.queryKeyFirstElement,
            useQueryClient: true,
            extractSortParams: false,
        });
    }, [resultQuery?.data, searchByParams, getDataSource, config.queryKeyFirstElement]);

    const resetColumnsCallbackRef = useRef<() => void>(() => {
        resetColumnsCallback?.();
    });

    const columnDefsEqualComparator = useMemo(() => {
        return getColumnDefsEqualComparator();
    }, []);

    const TableView = useMemo(() => {
        return (
            <AgGridCard
                ref={gridRef}
                useSSRMode
                getRowID={getRowID}
                SSRrowsToFetch={searchByParams.size}
                embedFullWidthRows
                columnDefs={columnDefs}
                gridTheme={gridTheme}
                unlimitedCalculatedHeight
                SSRDataSource={dataSource}
                maxNumberOfRowsToDisplay={10}
                showDefaultExportButton={false}
                suppressRowClickSelection
                customFlexibleColumns={customFlexibleColumns}
                containerStyles={{ marginTop: 0 }}
                getResizableMinWidthForColumn={getResizableMinWidthForColumn}
                useDragScroll
                autoSizePadding={autoSizePadding}
                tooltipShowDelay={tooltipShowDelay}
                rowsData={resultQuery.data?.results.research}
                defaultMaxWidth={defaultMaxWidth}
                onModelUpdatedRef={onModelUpdatedRef}
                onSortChangedRef={onSortChangedRef}
                onColumnMovedGetter={keepNoLeftPaddingOnMove}
                autosizeColumnsConfig={{
                    skipHeader: false,
                    skipHasPinnedColumnsCheck: true,
                }}
                suppressHeaderMenuButton={false}
                checkColumnDefsEqual={columnDefsEqualComparator}
                resetColumnsCallbackRef={resetColumnsCallbackRef}
                preferencesConfiguration={preferencesConfiguration}
                enableSavingUserSortModel
                enableSavingUserColumnsOrder
                enableSavingUserColumnsVisibility
                enableSavingUserColumnsWidths
                sortOnReset={sortOnReset}
                setUserPreferencesOnReset={setUserPreferences}
            />
        );
    }, [
        columnDefs,
        customFlexibleColumns,
        getResizableMinWidthForColumn,
        dataSource,
        searchByParams,
        autoSizePadding,
        onModelUpdatedRef,
        onSortChangedRef,
        resultQuery.data?.results.research,
        tooltipShowDelay,
        preferencesConfiguration,
        sortOnReset,
        columnDefsEqualComparator,
        setUserPreferences,
    ]);

    return TableView;
}
