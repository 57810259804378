import ETFModal from '@cfra-nextgen-frontend/shared/src/components/ETFModal';
import { ConfirmationModal } from '@cfra-nextgen-frontend/shared/src/components/Screener/ConfirmationModal';
import {
    WatchListContext,
    WatchListContextProvider,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerWatchListContext/Context';
import { CreateSavedItemTypes } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/savedScreens';
import { WatchListModal } from '@cfra-nextgen-frontend/shared/src/components/Watchlist/WatchListModals';
import { useUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import {
    IdsAndSelectionsPreferencesActions,
    IdsAndSelectionsTypeExtension,
    PreferenceType,
} from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { fontFamilies, UserSavedItemsLookupID } from '@cfra-nextgen-frontend/shared/src/utils';
import { SxProps } from '@mui/material';
import React, { useCallback, useContext, useImperativeHandle, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserPreferences, UserPreferencesSelectors, useUserPreferencesProps } from 'utils/preferences';

export type NewWatchlistRef = {
    handleOnClose: (showExistForm: boolean) => void;
    setCreateWatchlistData: (data: { cfra_security_trading_id: string; cfra_company_id: string }[]) => void;
};

export type NewWatchlistProps = {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    children: React.ReactNode;
    modalBoxStyles?: SxProps;
    onWatchlistCreated: (createdItem: CreateSavedItemTypes) => void;
    shouldShowExitForm: () => boolean;
};

const NewWatchlistModalContent = React.forwardRef(({ children, onWatchlistCreated }: any, ref) => {
    const [createWatchlistData, setCreateWatchlistData] =
        useState<{ cfra_security_trading_id: string; cfra_company_id: string }[]>();
    const navigate = useNavigate();
    const { watchListDispatcher } = useContext(WatchListContext);

    const navigateToWatchlist: () => void = useCallback(() => navigate('/watchlist'), [navigate]);

    const onSetCreateWatchlistData = (data: { cfra_security_trading_id: string; cfra_company_id: string }[]) => {
        setCreateWatchlistData(data);
        watchListDispatcher({ type: 'OpenSaveWatchlistModal' });
    };

    useImperativeHandle(ref, () => ({
        setCreateWatchlistData: onSetCreateWatchlistData,
    }));

    return (
        <>
            {children}
            <WatchListModal
                cardName='Watch List'
                showUpdateWatchlist={false}
                onFinishCallback={onWatchlistCreated}
                watchListData={createWatchlistData}
                savedItemType={UserSavedItemsLookupID.InstitutionalWatchlist}
                actionButton={{
                    text: 'View Watchlist',
                    clickAction: (data: CreateSavedItemTypes) => {
                        navigateToWatchlist();
                    },
                }}
            />
        </>
    );
});

export const NewWatchlistModal = React.forwardRef<NewWatchlistRef, NewWatchlistProps>(
    ({ showModal, setShowModal, children, modalBoxStyles, onWatchlistCreated, shouldShowExitForm }, ref) => {
        const [showExitConfirm, setShowExitConfirm] = useState(false);
        const { setUserPreferences } = useUserPreferences(useUserPreferencesProps);
        const watchlistContentRef = useRef<any>(null);

        const handleOnClose = (showExitForm: boolean) => {
            if (showExitForm) {
                setShowExitConfirm(true);
            } else {
                setShowModal(false);
            }
        };

        const onSetCreateWatchlistData = (data: { cfra_security_trading_id: string; cfra_company_id: string }[]) => {
            watchlistContentRef?.current?.setCreateWatchlistData(data);
        };

        useImperativeHandle(ref, () => ({
            handleOnClose,
            setCreateWatchlistData: onSetCreateWatchlistData,
        }));

        if (!showModal) return <></>;

        const callbackOnClose = () => {
            if (shouldShowExitForm()) {
                setShowExitConfirm(true);
            } else {
                handleOnClose(false);
            }
        };

        const onCreateWatchlistCallback = (fromCloseButton?: boolean, createdItem?: CreateSavedItemTypes) => {
            if (createdItem) {
                setUserPreferences?.<keyof typeof IdsAndSelectionsPreferencesActions, IdsAndSelectionsTypeExtension>(
                    PreferenceType.IdsAndSelectionsPreferences,
                    {
                        ...UserPreferencesSelectors[UserPreferences.WatchlistSelectLastViewedId],
                        action: IdsAndSelectionsPreferencesActions.SetLastViewedWatchlistId,
                        lastViewedWatchlistId: createdItem.id,
                    },
                );
                onWatchlistCreated?.(createdItem);
            }
            if (!fromCloseButton) {
                handleOnClose(false);
            }
        };

        return (
            <>
                <ETFModal
                    getOpenComponent={() => <></>}
                    title={'Create Watchlist'}
                    modalBoxStyles={{
                        ...{
                            maxWidth: '1400px',
                            maxHeight: 'calc(100vh - 10%)',
                        },
                        ...modalBoxStyles,
                    }}
                    titleStyle={{
                        boxShadow: 'none',
                        fontFamily: fontFamilies.GraphikMedium,
                        fontSize: '16px',
                        lineHeight: '28px',
                        paddingBottom: '0px',
                    }}
                    zIndex={1900}
                    externalOpenModal={showModal}
                    keepContentOnClose={false}
                    callbackOnClose={callbackOnClose}
                    closeButtonFontSize={18}>
                    <WatchListContextProvider>
                        <NewWatchlistModalContent
                            ref={watchlistContentRef}
                            children={children}
                            onWatchlistCreated={onCreateWatchlistCallback}
                        />
                    </WatchListContextProvider>
                </ETFModal>
                <ConfirmationModal
                    openModal={showExitConfirm}
                    modalText='Are you sure you want to close out of create watchlist?'
                    cancelCallback={() => {
                        setShowExitConfirm(false);
                    }}
                    cancelButtonText={'No'}
                    confirmCallback={() => {
                        setShowExitConfirm(false);
                        setShowModal(false);
                    }}
                    confirmButtonText={'Yes'}
                />
            </>
        );
    },
);
