import { BannerCard, childContainerStyles } from '@cfra-nextgen-frontend/shared/src/components/Card/BannerCard';
import { DividerStyle2 } from '@cfra-nextgen-frontend/shared/src/components/Divider/StyledDividers';
import { ItemVariant5 } from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/ItemVariant5';
import { getRequestParamsPropsVariant1 } from '@cfra-nextgen-frontend/shared/src/components/LinkGetter/researchLinkGetter';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ScreenerResearchData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import {
    ApiNames,
    ProductLid,
    RequestTypes,
    ResearchTypeId,
    UsageTypeLid,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { Stack } from '@mui/material';
import TrendingBanner from 'assets/images/trending_banner.svg';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { researchLinkGetterParams } from 'utils/researchLinkGetter';

const ALLOWED_RESEARCH_IN_TRENDING_SECTION = [
    ResearchTypeId.LegalReport,
    ResearchTypeId.FrsCompanyReport,
    ResearchTypeId.FrsIndustryReport,
    ResearchTypeId.FrsIRAPS,
    ResearchTypeId.FrsBespokeEdge,
    ResearchTypeId.FrsDiagnosticReport,
];

export function TrendingCard() {
    const navigate = useNavigate();
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const [trendingReportIds, setTrendingReportIds] = useState([]);

    const usageTrendingQuery = sendSingleRequest?.(
        {
            productLid: ProductLid.ForensicAccountingResearch,
            usageTypeLid: UsageTypeLid.PageView,
            size: 10,
            elapsedTimeHrs: 24,
            researchReportTypeId: ALLOWED_RESEARCH_IN_TRENDING_SECTION.join(','),
            requestBody: {},
            config: {},
        },
        {
            requestType: RequestTypes.GET,
            path: 'usage-trending',
            queryKeyFirstElement: 'usageTrendingQuery',
            apiName: ApiNames.UserManagement,
        },
    ) as UseQueryResult<any>;

    const trendingScreenerQuery = sendSingleRequest?.(
        {
            path: 'research/screener',
            size: 10,
            securityType: 'research',
            view: 'research_hub',
            requestBody: {
                filters: {
                    values: {
                        'insights.research_report._id': {
                            values: trendingReportIds,
                        },
                    },
                },
            },
            config: {
                enabled: trendingReportIds.length !== 0,
            },
        },
        {
            requestType: RequestTypes.POST,
            path: 'research/screener',
            queryKeyFirstElement: 'trendingResearchScreenerQuery',
            apiName: ApiNames.Research,
        },
    ) as UseQueryResult<ScreenerResearchData>;

    useEffect(() => {
        if (usageTrendingQuery?.data?.data) {
            setTrendingReportIds(
                usageTrendingQuery?.data?.data?.map((d: any) => d.research_report_opensearch_id) || [],
            );
        }
    }, [usageTrendingQuery?.data?.data]);

    const trendingResearchData = useMemo(() => {
        const data: any[] = [];
        if (trendingReportIds && !trendingScreenerQuery?.isLoading && trendingScreenerQuery?.data?.results?.research) {
            trendingReportIds.forEach((id: any, idx: number) => {
                const research = trendingScreenerQuery?.data?.results?.research.find((item: any) => item.id === id);
                if (research) {
                    data.push(research);
                }
            });
        }
        return data;
    }, [trendingReportIds, trendingScreenerQuery?.isLoading, trendingScreenerQuery?.data?.results?.research]);

    const onPillClickHandler = useCallback(
        (url: string) => {
            navigate(url);
        },
        [navigate],
    );

    return (
        <BannerCard
            title='Trending'
            bannerImage={TrendingBanner}
            containerStyles={{ height: '100%' }}
            childrenContainerSx={(theme: any) => ({
                ...childContainerStyles,
                [theme.breakpoints.down(1000)]: { maxHeight: 'none' }
            })}
            isLoading={usageTrendingQuery?.isLoading || trendingScreenerQuery?.isLoading}
            noResults={(trendingScreenerQuery?.data?.results?.research?.length || 0) === 0}>
            <Stack divider={<DividerStyle2 />} spacing={1}>
                {trendingResearchData.map((research: any, idx: number) => {
                    const primaryTickers =
                        research?.research_report_security__trading?.filter((d: any) => d?.is_primary) || [];

                    return (
                        <ItemVariant5<ScreenerResearchData>
                            key={idx}
                            index={idx}
                            topText={research?.research_report?.research_type_name || ''}
                            topSubText={moment(research?.research_report.publish_timestamp).format('MMMM D, YYYY')}
                            middleSubText={research?.research_report?.teaser || ''}
                            maxMiddleSubTextLength={100}
                            middleTitle={research?.research_report?.title}
                            useLinkGetterParams={researchLinkGetterParams}
                            bottomPillItems={primaryTickers?.map((company: any) => ({
                                content: company?.research_report_security?.security_trading?.ticker_symbol,
                                tooltipContent: company?.research_report_security?.company.company_name,
                                onClick: () =>
                                    onPillClickHandler(
                                        `/company-profile/security/${company.research_report_security?.security_trading?.cfra_security_trading_id}`,
                                    ),
                            }))}
                            bottomPillItemsThreshold={3}
                            handleOuterSetRequestParamsProps={(setRequestParamsProps) => {
                                setRequestParamsProps(getRequestParamsPropsVariant1(research.id));
                            }}
                        />
                    );
                })}
            </Stack>
        </BannerCard>
    );
}
