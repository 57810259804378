import { AppRoutes as SharedAppRoutes } from '@cfra-nextgen-frontend/shared/src/routes/AppRoutes';
import { RouteType } from '@cfra-nextgen-frontend/shared/src/routes/types';
import { setUpAwsAmplifyRememberMeListener } from '@cfra-nextgen-frontend/shared/src/routes/utils';
import { exportSSRAgGrid } from 'components/excelExport/export';
import { sendMultipleRequest } from 'components/screener/api/screener';
import { getDataSource, getSsrDataExportFn } from 'components/screener/ssr';
import { AccountingRoutes } from 'features/accountingLens/routes/AccountingRoutes';
import { Login } from 'features/auth/components/Login';
import { BespokeRoutes } from 'features/bespoke/routes/BespokeRoutes';
import { CapitolInsightsTrialForm } from 'features/capitolInsightsTrialForm';
import { CapitolInsightsEmailConfirmation } from 'features/capitolInsightsTrialForm/confirmationPage';
import { CompanyProfileRoutes } from 'features/companyProfile/routes/CompanyProfileRoutes';
import { ContactUsRoutes } from 'features/contactUs/routes/ContactUsRoutes';
import { HomeRoutes } from 'features/home';
import { LegalEdgeRoutes } from 'features/legalEdge/routes/LegalEdgeRoutes';
import { PdfViewerRoutes } from 'features/pdfViewer/routes/PdfViewerRoutes';
import { ResearchHubRoutes } from 'features/researchHub/routes/ResearchHubRoutes';
import { ScoresRoutes } from 'features/scores/routes/ScoresRoutes';
import { WatchlistRoutes } from 'features/watchlist/routes/WatchlistRoutes';
import { useEffect, useRef } from 'react';
import { AuthenticatedRoutesWrapper } from 'routes/AuthenticatedRoutesWrapper';
import { sendSingleInfiniteRequest, sendSingleRequest } from 'utils/api';
import { PageNames, PageNamesToRoutePaths } from 'utils/enums';
import { useUserPreferencesProps } from 'utils/preferences';

const routes: Array<RouteType> = [
    { path: '*', element: <HomeRoutes /> },
    { path: `${PageNamesToRoutePaths[PageNames.CompanyProfile]}/*`, element: <CompanyProfileRoutes /> },
    {
        path: `${PageNamesToRoutePaths[PageNames.ResearchHub]}/*`,
        element: <ResearchHubRoutes />,
    },
    { path: `${PageNamesToRoutePaths[PageNames.Watchlists]}/*`, element: <WatchlistRoutes /> },
    { path: '/accounting-lens/*', element: <AccountingRoutes /> },
    {
        path: `${PageNamesToRoutePaths[PageNames.Scores]}/*`,
        element: <ScoresRoutes />,
    },
    {
        path: `${PageNamesToRoutePaths[PageNames.Bespoke]}/*`,
        element: <BespokeRoutes />,
    },
    {
        path: `${PageNamesToRoutePaths[PageNames.LegalEdge]}/*`,
        element: <LegalEdgeRoutes />,
    },
    {
        path: `${PageNamesToRoutePaths[PageNames.ContactUs]}/*`,
        element: <ContactUsRoutes />,
    },
    {
        path: `/pdf/*`,
        element: <PdfViewerRoutes />,
    },
];

const unauthenticatedRoutes = [
    { path: '/trial', element: <CapitolInsightsTrialForm /> },
    { path: '/email-confirmation-success', element: <CapitolInsightsEmailConfirmation /> },
];

const userContextProviderProps = {
    preferencesFetcherProps: {
        ...useUserPreferencesProps,
        updatePreferencesQueryConfig: { cacheTime: 500 },
    },
};

export function AppRoutes() {
    const rememberMe = useRef<boolean>();

    useEffect(() => {
        setUpAwsAmplifyRememberMeListener(rememberMe);
    }, []);

    return (
        <SharedAppRoutes
            loginElement={<Login rememberMe={rememberMe} />}
            unauthenticatedRoutes={unauthenticatedRoutes}
            authenticatedRoutesJsxGetterProps={{
                authenticatedRoutesWrapperJsx: <AuthenticatedRoutesWrapper />,
                projectSpecificResources: {
                    sendSingleRequest,
                    sendMultipleRequest,
                    sendSingleInfiniteRequest,
                    getDataSource,
                    getSsrDataExportFn,
                    exportSSRAgGrid,
                },
                routes,
            }}
            userContextProviderProps={userContextProviderProps}
        />
    );
}
