import { Typography } from '@cfra-nextgen-frontend/shared';
import Background from '@cfra-nextgen-frontend/shared/src/assets/images/BespokeServices.png';
import BackgroundForm from '@cfra-nextgen-frontend/shared/src/assets/images/BespokeServicesForm.png';
import { ETFCard } from '@cfra-nextgen-frontend/shared/src/components/ETFCard';
import { ExpandableAccordion } from '@cfra-nextgen-frontend/shared/src/components/ExpandableAccordion';
import { HeroSection } from '@cfra-nextgen-frontend/shared/src/components/HeroSection/HeroSection';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout/Grid';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils';
import { Box, styled, SxProps, TypographyProps, useMediaQuery } from '@mui/material';
import { ContactForm } from 'components/ContactForm/ContactForm';
import { BespokeFeatureList } from './BespokeFeatureList';

const courseContent = {
    title: 'Training & Seminars',
    description:
        'CFRA’s training sessions and seminars discuss forensic accounting techniques and use case studies to educate participants on the various levers management may employ to hide operational risks.  Training sessions can be tailored or developed to suit your specific needs',
    topics: [
        {
            title: 'Financial Shenanigans',
            content:
                '<ul><li>Covers manipulation of key metrics using company examples.</li><li>Identifies tools to detect these techniques through financial analysis.</li></ul>',
        },
        {
            title: 'Case Study Exercise: Initial Red Flags in Company Disclosures',
            content: 'Participants review financial statements to identify concerning trends or disclosures.',
        },
        {
            title: 'Accounting & Financial Reporting: What You Need to Know',
            content: 'Covers key accounting topics relevant to market analysis for the current year.',
        },
        {
            title: 'Cash Flow Shenanigans',
            content: 'Reviews examples of companies manipulating cash flows to boost reported metrics.',
        },
        {
            title: 'Quantitative Screening Methodology',
            content: 'Discusses screening tools using quality of earnings criteria.',
        },
        {
            title: 'Accounting Basics for The Non-Accounting Professional',
            content: 'Teaches basic accounting concepts and financial statement preparation for non-accountants.',
        },
    ],
};

const featureData = [
    {
        category: 'Quarterly Data Review & Analysis',
        items: [
            'Review 16 quarters of financial data for client-listed companies.',
            'Screen companies across four risk categories.',
            'Conduct proprietary analysis.',
        ],
    },
    {
        category: 'Custom Reporting',
        items: [
            'Provide a summary section showing areas of risk by company.',
            'Include a two-page detail per company describing findings by risk category.',
        ],
    },
    {
        category: 'Quarterly Conference Call',
        items: [
            'Host a conference call led by the lead analyst to review findings and discuss risk areas.',
            'Allow client teams to ask questions about companies or findings.',
        ],
    },
    {
        category: 'Recommend Next Steps',
        items: ['Identify companies that warrant deeper analysis based on highlighted risk areas.'],
    },
];

const sectionsData = {
    comprehensiveInsights: {
        title: 'Comprehensive Insights',
        description: 'Bespoke Solutions includes portfolio risk monitoring and bespoke reports.',
    },
};

const StyledTitleVariant1 = styled(Typography)({
    fontFamily: fontFamilies.GraphikSemibold,
    fontWeight: 600,
    fontSize: '18px',
    color: '#002B5A',
    marginTop: '20px',
});

function StyledDescriptionVariant1(props: TypographyProps) {
    return (
        <Typography
            variant='h3'
            textAlign='left'
            {...props}
            sx={{
                ...props.sx,
                color: '#3C3C3C',
                fontFamily: fontFamilies.GraphikRegular,
                fontSize: '17px',
                marginTop: '13px',
                lineHeight: '1.6em',
                position: 'relative',
                textAlign: 'left',
            }}>
            {props.children}
        </Typography>
    );
}

function FeaturesSection({ containerSx }: { containerSx?: SxProps }) {
    return (
        <Grid>
            <Typography
                variant='h3'
                textAlign='left'
                style={{
                    fontFamily: fontFamilies.GraphikMedium,
                    fontWeight: 500,
                    fontSize: '18px',
                    color: '#002B5A',
                    marginBottom: '20px',
                }}>
                Features
            </Typography>
            <BespokeFeatureList features={featureData} containerSx={containerSx} />
        </Grid>
    );
}

export const BespokeServicesHome = () => {
    const isMobile = useMediaQuery('(max-width:768.5px)');

    return (
        <Grid container style={{ display: 'block' }}>
            {!isMobile && (
                <HeroSection
                    subtitle='BESPOKE EDGE'
                    title='Bespoke Solutions'
                    textLines={[
                        'Bespoke Solutions and Training Focuses on our time-tested accounting research',
                        'methodologies on the companies that matter most to you and help to meet your',
                        'objectives',
                    ]}
                    backgroundImage={Background}
                />
            )}
            <ETFCard
                containerStyles={{
                    padding: isMobile ? '12px' : '24px',
                    borderRadius: '8px',
                    margin: 0,
                    height: 'fit-content',
                }}>
                <Grid sx={{ backgroundColor: '#fff', width: '100%' }}>
                    {isMobile ? (
                        <>
                            <Grid
                                sx={{
                                    width: '100%',
                                    position: 'relative',
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: '33px',
                                        left: 0,
                                        width: '100%',
                                        height: '1px',
                                        borderBottom: '2px solid #DDDDDD',
                                        backgroundSize: '5px 1px',
                                    },
                                }}>
                                <Typography
                                    variant='h3'
                                    textAlign='left'
                                    style={{
                                        fontFamily: fontFamilies.GraphikSemibold,
                                        fontWeight: 500,
                                        fontSize: '18px',
                                        color: '#002B5A',
                                        paddingBottom: isMobile ? '35px' : '12px',
                                    }}>
                                    Bespoke Solutions
                                </Typography>
                            </Grid>
                        </>
                    ) : (
                        <>
                            <Typography
                                variant='h3'
                                textAlign='left'
                                style={{
                                    fontFamily: fontFamilies.GraphikMedium,
                                    fontWeight: 500,
                                    fontSize: '18px',
                                    color: '#002B5A',
                                    marginBottom: '12px',
                                }}>
                                Request a Bespoke
                            </Typography>
                            <StyledTitleVariant1
                                sx={{
                                    marginTop: 'unset',
                                    fontSize: '40px',
                                }}>
                                {sectionsData.comprehensiveInsights.title}
                            </StyledTitleVariant1>
                            <StyledDescriptionVariant1
                                sx={{
                                    marginBottom: '39px',
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        bottom: '-18px',
                                        left: 0,
                                        width: '100%',
                                        height: '1px',
                                        backgroundImage: 'radial-gradient(circle, #aaa 1px, transparent 1px)',
                                        backgroundSize: '5px 1px',
                                    },
                                }}>
                                {sectionsData.comprehensiveInsights.description}
                            </StyledDescriptionVariant1>
                        </>
                    )}
                </Grid>
                <Grid
                    sx={{
                        position: 'relative',
                        paddingBottom: '16px',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                    }}>
                    <Grid>
                        <Grid
                            sx={{
                                maxWidth: '787px',
                            }}></Grid>
                        {!isMobile && <FeaturesSection />}
                    </Grid>
                    <Box
                        sx={{
                            maxWidth: 505,
                            width: '100%',
                            margin: '0 auto',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            backgroundColor: '#fff',
                            borderRadius: '20px',
                            border: '1px solid #C7C3C3',
                            marginTop: isMobile ? '13px' : 'unset',
                        }}>
                        <img
                            src={BackgroundForm}
                            alt='Bespoke Research'
                            style={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: '8px 8px 0 0',
                                marginBottom: '16px',
                            }}
                        />
                        <Box sx={{ padding: '4px 36px 20px' }}>
                            <Typography
                                variant='h5'
                                sx={{
                                    fontWeight: '700',
                                    fontSize: '34px',
                                    marginBottom: '19px',
                                    color: 'rgba(0, 43, 90, 1)',
                                }}>
                                Request Bespoke Research
                            </Typography>
                            <Typography
                                variant='body2'
                                color='textSecondary'
                                sx={{
                                    fontWeight: '400',
                                    fontSize: '16px',
                                    lineHeight: '1.75em',
                                    color: 'rgba(60, 60, 60, 1)',
                                }}>
                                Please complete the form below or contact client services&nbsp;at +1 212 981 1062 to
                                request bespoke research.
                            </Typography>
                            <ContactForm
                                thankyouMessage='Thank you for reaching out! A member of our team will contact you soon.'
                                subjectPrefix='Bespoke Request:'
                                hasSubjectField
                            />
                        </Box>
                    </Box>
                    {isMobile && (
                        <>
                            <StyledTitleVariant1>{sectionsData.comprehensiveInsights.title}</StyledTitleVariant1>
                            <StyledDescriptionVariant1>
                                {sectionsData.comprehensiveInsights.description}
                            </StyledDescriptionVariant1>
                            <Grid
                                sx={{
                                    width: '100%',
                                    paddingTop: '30px',
                                    position: 'relative',
                                    '&::before': {
                                        content: '""',
                                        position: 'absolute',
                                        top: '15px',
                                        left: 0,
                                        width: '100%',
                                        height: '1px',
                                        backgroundImage: 'radial-gradient(circle, #aaa 1px, transparent 1px)',
                                        backgroundSize: '5px 1px',
                                    },
                                }}></Grid>
                            <FeaturesSection
                                containerSx={{
                                    gap: '11px',
                                    justifyContent: 'center',
                                }}
                            />
                        </>
                    )}
                </Grid>
                <Grid
                    sx={{
                        width: '100%',
                        paddingBottom: '16px',
                        paddingTop: '37px',
                        position: 'relative',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: '8px',
                            left: 0,
                            width: '100%',
                            height: !isMobile ? '1px' : 'unset',
                            backgroundImage: 'radial-gradient(circle, #aaa 1px, transparent 1px)',
                            backgroundSize: '5px 1px',
                        },
                    }}>
                    <ExpandableAccordion
                        content={courseContent}
                        titleStyle={{ fontSize: isMobile ? '20px' : '40px' }}
                    />
                </Grid>
            </ETFCard>
        </Grid>
    );
};
