import { ETFDownloadButton } from '@cfra-nextgen-frontend/shared';
import { SxProps } from '@mui/material';
import { CSSProperties } from 'react';
import { ETFMenu } from './ETFMenu';

export const menuVariant3ItemStyles: Record<string, React.CSSProperties> = {
    menuItemIcon: {
        marginLeft: '10px',
        marginRight: '10px',
    },
    menuItemText: {
        display: 'block',
        whiteSpace: 'normal',
        color: '#3C3C3C',
        lineHeight: '32px',
    },
};

export type MenuVariant3Props = {
    menuItems: any;
    containerSx?: CSSProperties;
    keepOpen?: boolean;
    hoverSx?: CSSProperties;
    paperProps?: SxProps;
    tooltipText?: string;
    iconComponent?: React.ReactNode;
    menuItemStyles?: SxProps;
    useClickableBox?: boolean;
};

export function MenuVariant3(props: MenuVariant3Props) {
    return (
        <div style={{ marginLeft: 10, marginRight: 10, paddingTop: 10, ...props.containerSx }}>
            <ETFMenu
                useClickableBox={props.useClickableBox}
                keepOpen={props.keepOpen}
                menuItemStyles={{
                    fontSize: '13px',
                    fontWeight: 400,
                    fontFamily: 'GraphikRegular',
                    padding: '6px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 0,
                    border: 0,
                    width: '100%',
                    '&:hover': {
                        border: 0,
                        backgroundColor: '#E0E0E0',
                        ...props.hoverSx,
                    },
                    ...props.menuItemStyles,
                }}
                menuItems={props.menuItems}
                paperProps={{
                    width: '124px',
                    ...props.paperProps,
                }}
                onHoverItemStyles={{
                    backgroundColor: '#E0E0E0',
                }}
                addBorderBetweenItems={false}
                transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
                {props.iconComponent || <ETFDownloadButton tooltipText={props.tooltipText ?? 'Watchlist Actions'} />}
            </ETFMenu>
        </div>
    );
}
