import { SearchSyntaxInfo } from '@cfra-nextgen-frontend/shared/src/components/InformationPopup/SearchSyntaxInfo';
import { ItemVariant4 } from '@cfra-nextgen-frontend/shared/src/components/ItemComponents/ItemVariant4';
import { SearchInputVariant2 } from '@cfra-nextgen-frontend/shared/src/components/SearchInput/SearchInputVariant2';
import { getOptionsContainer } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/optionsContainer';
import { Results1Column } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/resultsComponents/Results1Column';
import { TitleDividerSubtitle } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/TitleWithSubtitle';
import {
    ShowHideStrategies,
    TypeSearch,
    TypeSearchProps,
} from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/TypeSearch';
import { shadowBottomStylesVariant1 } from '@cfra-nextgen-frontend/shared/src/utils/shadows';
import { Box } from '@mui/material';
import { determineGetCompanySuggesterOptions } from 'features/topNavigation/companySuggester';
import { useMemo } from 'react';
import { TypographyStyle7 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';

// need on mobile devices to show search results box smoothly
import '@cfra-nextgen-frontend/shared/src/utils/animation/smoothArrival.scss';

const mobileVariantResultsBoxHeight = '345px';
const resultsRightColumnTitle = 'Text Search';
const resultsLeftColumnTitle = 'Companies';
const toggleHeight = 44;
const togglePaddingBottom = 10;
const toggleContainerHeight = toggleHeight + togglePaddingBottom;
const textSearchSubTitle = 'Select text term to add to your query';

type TypeSearchComponentProps = {
    onCompanyClick: (cfraCompanyId: string, ticker: string, exchangeCode: string) => void;
    onSearchTermClick: (searchTerm: string) => void;
    isMobileVariant: boolean;
    queriesKeyFirstElementPostfix?: string;
};

export function TypeSearchComponent({
    onCompanyClick,
    onSearchTermClick,
    isMobileVariant,
    queriesKeyFirstElementPostfix,
}: TypeSearchComponentProps) {
    const desktopVariantProps: Partial<TypeSearchProps> = useMemo(
        () => ({
            titles: {
                left: resultsLeftColumnTitle,
            },
            titleNodes: {
                right: <TitleDividerSubtitle title={resultsRightColumnTitle} subTitle={textSearchSubTitle} />,
            },
        }),
        [],
    );

    const mobileVariantProps: Partial<TypeSearchProps> = useMemo(
        () => ({
            SearchWrapperComponent: ({ children }) => (
                <Box
                    sx={{
                        width: '100%',
                        marginBottom: '10px',
                        paddingBottom: '10px',
                        ...shadowBottomStylesVariant1,
                    }}>
                    {children}
                </Box>
            ),
            BelowInputPlaceholderComponent: ({ showResultsBox }) => (
                <Box
                    sx={{
                        transition: 'all 0.5s ease',
                        width: '100%',
                        ...(showResultsBox
                            ? {
                                  height: mobileVariantResultsBoxHeight,
                                  minHeight: mobileVariantResultsBoxHeight,
                                  maxHeight: mobileVariantResultsBoxHeight,
                              }
                            : {
                                  height: '0px',
                                  minHeight: '0px',
                                  maxHeight: '0px',
                              }),
                    }}></Box>
            ),
            titleNodes: {
                right: <TypographyStyle7 sx={{ padding: '0px 6px' }}>{textSearchSubTitle}</TypographyStyle7>,
            },
            popperSx: { zIndex: 1000 },
            popperClassName: 'cfra-box-smooth-arrival',
            titleWithScrollableAreaPropsToOverride: {
                left: {
                    containerSx: {
                        height: `calc(100% - ${toggleContainerHeight}px)`,
                    },
                    scrollableAreaContainerWrapperSx: {},
                    scrollableAreaContainerSx: {
                        padding: '0px 6px',
                    },
                },
                right: {
                    scrollableAreaContainerWrapperSx: {},
                    scrollableAreaContainerSx: {
                        padding: '0px 6px',
                    },
                },
            },
            ResultsColumnsComponent: ({ leftOptions, rightOptions }) => (
                <Results1Column
                    options={{
                        [resultsLeftColumnTitle]: leftOptions,
                        [resultsRightColumnTitle]: rightOptions,
                    }}
                    toggleContainerSx={{
                        width: '243px',
                        height: `${toggleContainerHeight}px`,
                        paddingBottom: `${togglePaddingBottom}px`,
                    }}
                />
            ),
        }),
        [],
    );

    return (
        <TypeSearch
            showHideStrategy={ShowHideStrategies.UseShowResultsContainer}
            showSearchInput
            outerOnInputKeyDownCallback={(event) => {
                if (event.key !== 'Enter') {
                    return;
                }

                const value = (event.target as HTMLInputElement).value;
                onSearchTermClick(value);
            }}
            getLeftOptions={determineGetCompanySuggesterOptions({
                size: 25,
                externalOnClickCallback: (data) => {
                    onCompanyClick(
                        data?._source?.['company_security.company.cfra_company_id'],
                        data?._source?.['company_security.security_trading.ticker_symbol'],
                        data?._source?.['company_security.security_trading.exchange_lid.exchange_code'],
                    );
                },
                passNavigateUrl: false,
                getOptionsContainer,
                queryKeyFirstElementPostfix: queriesKeyFirstElementPostfix,
                itemTextContainersSx: isMobileVariant
                    ? {
                          left: {
                              width: '40%',
                          },
                          right: {
                              width: '60%',
                          },
                      }
                    : undefined,
            })}
            getRightOptions={({ inputValue, onLinkClickCallback, titleWithScrollableAreaProps }) => {
                const OptionsContainer = getOptionsContainer(titleWithScrollableAreaProps);

                return (
                    <OptionsContainer key='Text search search title and search term component'>
                        <ItemVariant4
                            onClick={() => {
                                onSearchTermClick(inputValue);
                                onLinkClickCallback?.();
                            }}
                            value={inputValue}
                            containerSx={isMobileVariant ? { padding: '0px' } : {}}
                        />
                    </OptionsContainer>
                );
            }}
            SearchInputComponent={SearchInputVariant2}
            searchInputComponentProps={{
                afterInputSlot: (
                    <Box
                        sx={{
                            paddingLeft: '8px',
                        }}>
                        <SearchSyntaxInfo buttonFontSize={21} />
                    </Box>
                ),
                placeholder: isMobileVariant ? 'Search' : 'Search by ticker, keyword or phrase.',
                containerSx: isMobileVariant ? { maxWidth: 'unset' } : {},
            }}
            contextBoxStyles={{
                maxHeight: 'calc(95vh - 163px)',
                ...(isMobileVariant
                    ? {
                          height: mobileVariantResultsBoxHeight,
                          minHeight: mobileVariantResultsBoxHeight,
                          maxHeight: mobileVariantResultsBoxHeight,
                          boxShadow: 'none',
                          border: 'none',
                          padding: '6px 24px 0px 24px',
                          overflow: 'hidden',
                      }
                    : {}),
            }}
            offset={[0, 21]}
            {...(isMobileVariant ? mobileVariantProps : desktopVariantProps)}
        />
    );
}
