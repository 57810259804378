import { CompanyOverview } from './components/CompanyOverview';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { useParams } from 'react-router-dom';
import { getCompanyDetails, getHasConcernsPdf, getIRAPPdf, getQueryConfig, getScoresPdf } from './api/company';
import { CompanyProfile } from './components/CompanyProfile';
import { CompanyParams, PDFURLResponse } from './types/company';
import { CompanyHeadlines } from './components/CompanyHeadlines';
import { useUsageLogger } from '@cfra-nextgen-frontend/shared/src/hooks/useUsageLogger';
import { UsageRequestTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { useContext, useCallback } from 'react';
import { ScreenerDataWithGenericResultsKey } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { UseQueryResult } from 'react-query';
import { ThemeProvider, CssBaseline, createTheme, ThemeOptions } from '@mui/material';
import { PageThemeOptions } from 'components/themes/theme';
import { IndustryResearch } from './components/IndustryResearch';
import { CashFlow } from './components/CashFlow';
import { EarningsScore } from './components/EarningsScore';
import { useLinkGetter } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { getRequestParamsPropsVariant1 } from '@cfra-nextgen-frontend/shared/src/components/LinkGetter/researchLinkGetter';
import { researchLinkGetterParams } from 'utils/researchLinkGetter';
import { getUsageApiReqBody } from 'utils/usage';
import { deepmerge } from '@mui/utils';
import { HideOnMdAndAbove, HideOnMdAndBelow } from 'utils/responsive';
import { customBreakPointValues } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';


const extendedPageThemeOptions: ThemeOptions = deepmerge(PageThemeOptions, {
    breakpoints: {
        values: customBreakPointValues,
    },
});

const extendedPageTheme = createTheme(extendedPageThemeOptions);

export default function CompanyProfilePage() {
    const companyParams = useParams<keyof CompanyParams>() as CompanyParams;
    const { sendSingleRequest } = useContext(ProjectSpecificResourcesContext);
    const { setRequestParamsProps } = useLinkGetter(...researchLinkGetterParams);

    if (!sendSingleRequest) {
        throw new Error('sendSingleRequest is not defined');
    }

    const handleLinkClick = useCallback(
        (researchId: string) => {
            setRequestParamsProps(getRequestParamsPropsVariant1(researchId));
        },
        [setRequestParamsProps],
    );

    useUsageLogger({
        requestBody: getUsageApiReqBody(UsageRequestTypes.CompanyViewed, companyParams),
        config: { enabled: true },
        actionType: UsageRequestTypes.CompanyViewed,
    });

    const company = sendSingleRequest(
        getCompanyDetails(companyParams),
        getQueryConfig('getCompanyDetails', 'company'),
    ) as UseQueryResult<ScreenerDataWithGenericResultsKey<'company'>>;

    let gics_code = null;
    let is_biggest_concern = false;
    companyParams.enableQuery = false;

    if (
        !company.isLoading &&
        company?.data?.results?.company !== undefined &&
        company.data.results.company.length > 0
    ) {
        let companyData = company.data?.results?.company[0];
        gics_code = companyData.company_security.company_sector?.lookup_gics_subind_lid?.key;
        is_biggest_concern = companyData.frs_biggest_concerns_list?.is_biggest_concern;
        companyParams.enableQuery = true;
    }

    const concerns_report = sendSingleRequest(
        getHasConcernsPdf(companyParams, is_biggest_concern),
        getQueryConfig('getHasConcernsPdf'),
    ) as UseQueryResult<PDFURLResponse>;

    const iraps_report = sendSingleRequest(
        getIRAPPdf(companyParams.enableQuery, gics_code),
        getQueryConfig('getIRAPPdf'),
    ) as UseQueryResult<PDFURLResponse>;

    const scores_report = sendSingleRequest(
        getScoresPdf(companyParams),
        getQueryConfig('getScoresPdf'),
    ) as UseQueryResult<PDFURLResponse>;

    const getReportId = (result: UseQueryResult<PDFURLResponse>) =>
        (result.isLoading || result.data?.results.research.length === 0 ? null : result.data?.results.research[0].id) ||
        '';

    return (
        <ThemeProvider theme={extendedPageTheme}>
            <CssBaseline />
            <Grid container>
                <Grid item xs={12}>
                    <CompanyOverview
                        company={company}
                        handleLinkClick={handleLinkClick}
                        reportID={getReportId(concerns_report)}
                    />
                </Grid>
                <Grid container sx={{ paddingTop: '36px' }} spacing={3}>
                    <Grid item xs={12} md={8}>
                        <CompanyProfile
                            company={company}
                            handleLinkClick={handleLinkClick}
                            reportID={getReportId(iraps_report)}
                        />
                        <HideOnMdAndBelow>
                            <IndustryResearch enableQuery={companyParams.enableQuery} gics_code={gics_code}></IndustryResearch>
                        </HideOnMdAndBelow>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CompanyHeadlines queryParams={companyParams} isLoading={company.isLoading} gics_code={gics_code} customTheme={extendedPageTheme} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <HideOnMdAndAbove>
                        <IndustryResearch enableQuery={companyParams.enableQuery} gics_code={gics_code}></IndustryResearch>
                    </HideOnMdAndAbove>
                </Grid>
                <Grid item xs={12} md={8} lg={6} sx={{ paddingTop: '36px' }}>
                    <EarningsScore
                        company={company}
                        enableQuery={companyParams.enableQuery}
                        handleLinkClick={handleLinkClick}
                        reportID={getReportId(scores_report)}
                    />
                </Grid>
                <Grid item xs={12} md={8} lg={6} sx={{ paddingTop: '36px' }} paddingLeft={{ lg: '24px' }}>
                    <CashFlow
                        company={company}
                        enableQuery={companyParams.enableQuery}
                        handleLinkClick={handleLinkClick}
                        reportID={getReportId(scores_report)}
                    />
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
