import clearIcon from '@cfra-nextgen-frontend/shared/src/assets/icons/clear-icon.svg';
import { fontFamilies } from '@cfra-nextgen-frontend/shared/src/utils/fonts';
import { Button, SxProps } from '@mui/material';

type ClearButtoProps = {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    sx?: SxProps;
};

export function ClearButton({ onClick, sx }: ClearButtoProps) {
    return (
        <Button
            variant='outlined'
            color='primary'
            onClick={onClick}
            sx={{
                textTransform: 'none',
                borderColor: 'transparent',
                '&:hover': {
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                },
                fontFamily: fontFamilies.GraphikRegular,
                fontSize: '13.5px',
                ...sx,
            }}>
            <img src={clearIcon} alt='Clear Icon' style={{ width: '26px', height: '24px', marginRight: '12px' }} />
            Clear
        </Button>
    );
}
