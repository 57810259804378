import { ETFMenu, MaterialIcons, RoundedIconTextButton } from "@cfra-nextgen-frontend/shared";
import { CFRAMuiIconWrapper } from "@cfra-nextgen-frontend/shared/src/components/Icon";
import { WatchListButtonTheme } from "@cfra-nextgen-frontend/shared/src/components/Screener/Watchlists/WatchListUtils";
import { createTheme, useMediaQuery } from "@mui/material";
import upload from '@cfra-nextgen-frontend/shared/src/assets/icons/upload.svg';
import search from '@cfra-nextgen-frontend/shared/src/assets/icons/search.svg';
import { StyledAvatar } from "@cfra-nextgen-frontend/shared/src/components/Avatar/Avatar";
import React, { useRef, useState } from "react";
import { FileUploadModalRef, NewWatchlistFileUploadModal } from "./newWatchlist/FileUploadModal";
import { CreateSavedItemTypes } from "@cfra-nextgen-frontend/shared/src/components/Screener/types/savedScreens";
import { CompanySearchModalRef, NewWatchlistCompanySearchModal } from "./newWatchlist/CompanySearchModal";
import { fontFamilies } from "@cfra-nextgen-frontend/shared/src/utils/fonts";
import { NewWatchlistModal, NewWatchlistRef } from "./newWatchlist/Modal";
import { customBreakPointValues } from "@cfra-nextgen-frontend/shared/src/components/themes/theme";


const styles: Record<string, React.CSSProperties> = {
    menuItemIcon: {
        marginLeft: '10px',
        marginRight: '10px'
    },
    menuItemText: {
        display: 'block',
        whiteSpace: 'normal',
        color: '#3C3C3C',
        lineHeight: '32px',
    }
}

const newWatchlistButtonIconTheme = createTheme({
    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: '#fff', 
                    fontSize: '20px'
                }
            }
        }
    }
})

const newWatchlistButtonTheme = createTheme(WatchListButtonTheme, {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: '#002B5A',
                        color: '#fff',
                    },
                    '&:hover svg path': {
                        fill: '#fff',
                    },
                    color: '#fff',
                    borderRadius: '8px',
                    backgroundColor: '#002B5A',
                    padding: '4px 15px',
                    minWidth: '163px',
                    width: 'auto',
                    height: '48px',
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    width: '18px',
                    height: '18px',
                    paddingRight: '2px',
                    marginLeft: '-3.5px',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontFamily: fontFamilies.GraphikRegular,
                    fontSize: '14px',
                    letterSpacing: '0.46px',
                    lineHeight: '22px',
                    width: 'inherit',
                },
            },
        },
    }
});

export function CreateWatchlist({ onWatchlistCreated }: { onWatchlistCreated?: (createdItem: CreateSavedItemTypes) => void }) {
    const [showFileUploadModal, setShowFileUploadModal] = useState(false);
    const [showCompanySearchModal, setShowCompanySearchModal] = useState(false);
    
    const newWatchlistModalRef = useRef<NewWatchlistRef>(null); 
    const fileUploadRef = useRef<FileUploadModalRef>(null); 
    const companySearchRef = useRef<CompanySearchModalRef>(null);
    const isSmallDevice = useMediaQuery(`(max-width:${customBreakPointValues.sm - 1}px)`);

    const newWatchlistMenuItems = [
        {
            itemName: <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledAvatar src={upload} style={styles.menuItemIcon} />
                <span style={styles.menuItemText}>By File Upload</span>
            </div>,
            callback: () => { setShowFileUploadModal(true); },
        },
        {
            itemName: <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledAvatar src={search} style={styles.menuItemIcon} />
                <span style={styles.menuItemText}>By Company Search</span>
            </div>,
            callback: () => { setShowCompanySearchModal(true); },
        }
    ];

    const onWatchlistCreatedCallback = (createdItem: CreateSavedItemTypes) => {
        onWatchlistCreated?.(createdItem);
    }

    const shouldShowExitForm = (ref: any) => {
        return ref?.current?.shouldShowExitForm ? ref?.current?.shouldShowExitForm() : true;
    }
    
    return (<>
        <ETFMenu.ETFMenu
            menuItemStyles={{
                fontSize: '13.5px',
                fontWeight: 400,
                fontFamily: 'GraphikRegular',
                padding: '6px',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 0,
                border: 0,
                width: '100%',
                '&:hover': {
                    border: 0,
                    backgroundColor: '#E0E0E0',
                },
            }}
            menuItems={newWatchlistMenuItems}
            paperProps={{
                width: '185px',
            }}
            onHoverItemStyles={{
                backgroundColor: '#E0E0E0'
            }}
            addBorderBetweenItems={false}
            transformOrigin={{ horizontal: 'center', vertical: 'top' }}>
            <RoundedIconTextButton
                theme={newWatchlistButtonTheme}
                icon={<CFRAMuiIconWrapper MuiIcon={MaterialIcons.MoreHoriz} theme={newWatchlistButtonIconTheme} />}
                buttonText={`New ${isSmallDevice ? '' : 'Watchlist'}`}
                tooltipText='Create a new Watchlist'
                iconRight={true}
                onClickCallback={() => {
                    // onClickCallback();
                    // watchListDispatcher({ type: 'OpenSaveWatchlistModal' });
                }}
                sx={(theme: any) => ({
                    [theme.breakpoints.down('md')]: {
                        minWidth: 'auto',
                        paddingX: '22px'
                    }
                })}
            />
        </ETFMenu.ETFMenu>
        {
        showFileUploadModal && 
            <NewWatchlistModal ref={newWatchlistModalRef} 
                showModal={showFileUploadModal} setShowModal={setShowFileUploadModal} onWatchlistCreated={onWatchlistCreatedCallback} 
                shouldShowExitForm={() => shouldShowExitForm(fileUploadRef)}>
                <NewWatchlistFileUploadModal 
                    ref={fileUploadRef}
                    handleCloseModal={(showExitForm: boolean) => newWatchlistModalRef?.current?.handleOnClose(showExitForm)} 
                    setCreateWatchlistData={
                        (data: { cfra_security_trading_id: string; cfra_company_id: string; }[]) => newWatchlistModalRef?.current?.setCreateWatchlistData(data)}
                />
            </NewWatchlistModal>
        }
        {
        showCompanySearchModal && 
            <NewWatchlistModal ref={newWatchlistModalRef} 
                showModal={showCompanySearchModal} setShowModal={setShowCompanySearchModal} onWatchlistCreated={onWatchlistCreatedCallback}
                shouldShowExitForm={() => shouldShowExitForm(companySearchRef)} modalBoxStyles={{ maxWidth: '800px' }}>
                <NewWatchlistCompanySearchModal 
                    ref={companySearchRef}
                    setCreateWatchlistData={
                        (data: { cfra_security_trading_id: string; cfra_company_id: string; }[]) => newWatchlistModalRef?.current?.setCreateWatchlistData(data)} 
                />
            </NewWatchlistModal>
        }
        </>
    )
}