import { useAuthenticator } from '@aws-amplify/ui-react';
import { useEffect, useMemo, useState } from 'react';
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { removeDataFromSessionStorage, setDataToSessionStorage } from '../utils/storage';
import { GetAuthenticatedRoutesJsxProps, RouteType } from './types';
import { getAuthenticatedRoutesJsx, getNotAuthenticatedRoutes, getNotAuthenticatedRoutesJsx } from './utils';

type AppRoutesProps = {
    loginElement: JSX.Element;
    authenticatedRoutesJsxGetterProps: GetAuthenticatedRoutesJsxProps;
    unauthenticatedRoutes?: Array<RouteType>;
    userContextProviderProps?: GetAuthenticatedRoutesJsxProps['userContextProviderProps'];
};

export const AppRoutes = ({
    authenticatedRoutesJsxGetterProps,
    loginElement,
    unauthenticatedRoutes,
    userContextProviderProps,
}: AppRoutesProps) => {
    const [resultJsx, setResultJsx] = useState(<></>);

    const { route, user } = useAuthenticator((context) => [context.route, context.user]);

    const routesJsx = useMemo(
        () =>
            getAuthenticatedRoutesJsx({
                ...authenticatedRoutesJsxGetterProps,
                projectSpecificResources: {
                    ...authenticatedRoutesJsxGetterProps.projectSpecificResources,
                    userId: user?.username,
                },
                userContextProviderProps,
            }),
        [user?.username, authenticatedRoutesJsxGetterProps, userContextProviderProps],
    );
    const authenticatedRouter = useMemo(() => {
        return createBrowserRouter(createRoutesFromElements(routesJsx));
    }, [routesJsx]);

    const notAuthenticatedRoutesJsx = useMemo(() => {
        return getNotAuthenticatedRoutesJsx(getNotAuthenticatedRoutes(loginElement, unauthenticatedRoutes));
    }, [loginElement, unauthenticatedRoutes]);
    const notAuthenticatedRouter = useMemo(() => {
        return createBrowserRouter(createRoutesFromElements(notAuthenticatedRoutesJsx));
    }, [notAuthenticatedRoutesJsx]);

    useEffect(() => {
        if (route === 'setup' || route === 'signIn') {
            const currentRoute = window.location.href.replace(window.location.origin, '');
            if (!currentRoute.includes('login')) {
                setDataToSessionStorage('preLoginRoute', currentRoute);
            }
            setResultJsx(<RouterProvider router={notAuthenticatedRouter} />);
            return;
        }

        if (route === 'authenticated' && user?.username) {
            removeDataFromSessionStorage('preLoginRoute');
            setResultJsx(<RouterProvider router={authenticatedRouter} />);
            return;
        }
    }, [authenticatedRouter, notAuthenticatedRouter, route, user?.username]);

    return resultJsx;
};
