import { PageWithComponentInHeader } from '@cfra-nextgen-frontend/shared/src/components/PageLayouts/PageWithComponentInHeader';
import { horizontalPaddingInSu, maxPageWidthInPx } from 'utils/lookAndFeel';

export function PageWrapper({ children }: { children: React.ReactNode }) {
    return (
        <PageWithComponentInHeader
            pageContainerSx={{
                maxWidth: '100%',
                paddingLeft: horizontalPaddingInSu,
                paddingRight: horizontalPaddingInSu,
                '@media (max-width: 768.5px)': {
                    paddingLeft: '5px',
                    paddingRight: '5px',
                },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                minHeight: 'unset',
            }}
            tabContainerSx={{
                paddingTop: horizontalPaddingInSu,
                paddingBottom: '0px',
                paddingLeft: 0,
                paddingRight: 0,
                maxWidth: maxPageWidthInPx,
                backgroundColor: 'unset',
                width: '100%',
            }}
            outletComponent={children}
        />
    );
}
