import { FormPillsRow, FormPillsRowProps } from '@cfra-nextgen-frontend/shared/src/components/Form/FormPillsRow';
import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { combineIntoFormElementName } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/shared';
import { useMemo } from 'react';
import { FilterProps } from './types';

export type ScreenerFormPillsRowProps = FilterProps & {
    secondaryStyleStartIndex?: FormPillsRowProps['secondaryStyleStartIndex'];
    customSortOrder?: FormPillsRowProps['customSortOrder'];
    imageMap: FormPillsRowProps['imageMap'];
    optionsToOverride?: FormPillsRowProps['optionsToOverride'];
    afterPillsSlot?: FormPillsRowProps['afterPillsSlot'];
    afterMoreButtonComponentWidth?: FormPillsRowProps['afterMoreButtonComponentWidth'];
};

export function ScreenerFormPillsRow({
    filtersData,
    filterMetadataKey,
    control,
    getValues,
    submitHandler,
    secondaryStyleStartIndex,
    customSortOrder,
    imageMap,
    optionsToOverride,
    afterPillsSlot,
    afterMoreButtonComponentWidth,
}: ScreenerFormPillsRowProps) {
    const options = useMemo(() => {
        return filtersData.data[filterMetadataKey]?.items || [];
    }, [filtersData, filterMetadataKey]);

    return (
        <FormPillsRow
            name={combineIntoFormElementName({
                componentName: Components.PillsRow,
                filterMetadataKey,
            })}
            options={options}
            control={control}
            getValues={getValues}
            submitHandler={submitHandler}
            secondaryStyleStartIndex={secondaryStyleStartIndex}
            customSortOrder={customSortOrder}
            imageMap={imageMap}
            optionsToOverride={optionsToOverride}
            afterPillsSlot={afterPillsSlot}
            afterMoreButtonComponentWidth={afterMoreButtonComponentWidth}
        />
    );
}
