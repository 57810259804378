import { CFRABadge } from '@cfra-nextgen-frontend/shared/src/components/Badge/Badge';
import { ResultsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { ChipItem } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/types';
import { BadgeProps, ThemeProvider } from '@mui/material';
import { BadgeThemeWithMenu } from 'components/themes/theme';
import { useContext, useMemo } from 'react';

export type FiltersBadgeProps = BadgeProps & { externalChipItems?: Record<string, ChipItem> };

export function FiltersBadge({ externalChipItems, children, ...props }: FiltersBadgeProps) {
    const {
        chipStateManager: {
            chipState: { chipItems: internalChipItems },
        },
    } = useContext(ResultsContext);

    const checkedCount = useMemo(() => {
        return Object.keys(externalChipItems || {}).length + Object.keys(internalChipItems || {}).length;
    }, [externalChipItems, internalChipItems]);

    return (
        <ThemeProvider theme={BadgeThemeWithMenu}>
            <CFRABadge {...props} badgeContent={checkedCount} color='secondary'>
                {children}
            </CFRABadge>
        </ThemeProvider>
    );
}
