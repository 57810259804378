import '@cfra-nextgen-frontend/shared/src/components/AgGrid/scss/GridThemeV2.scss';
import '@cfra-nextgen-frontend/shared/src/components/AgGrid/scss/HideVerticalScroll.scss';
import { breakpointsTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { ColDef, Column, ColumnMovedEvent, ModelUpdatedEvent, SortChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Dispatch, MutableRefObject } from 'react';
import { AgGridProps, ColumnAndGridApi } from './AgGrid';
import { ColumnDef } from './types';
import { getColumnId } from '../UserPreferences/utils/agGrid';

export function setCustomFlexWidths(isBelowLg: boolean, customFlexibleColumns: Array<string>) {
    return (agGridObject: ColumnAndGridApi) => {
        if (!agGridObject) {
            return;
        }

        const getWidth = (minWidth: number, maxWidth: number, allColumnsNames: Array<string>) => {
            if (isBelowLg) minWidth = 170;
            const columnsIntersection = allColumnsNames.filter((x) => customFlexibleColumns.includes(x));
            let width = isBelowLg
                ? maxWidth -
                  (breakpointsTheme.breakpoints.values['lg'] - globalThis.window.innerWidth) /
                      columnsIntersection.length
                : maxWidth;

            if (width < minWidth) return minWidth;
            if (width > maxWidth) return maxWidth;
            return width;
        };
        const columnDefs = agGridObject?.api.getColumnDefs() as Array<ColDef>;
        if (!columnDefs) return;
        const allColumnsNames: Array<string> = [];
        columnDefs.forEach((element) => {
            if (element.headerName) {
                allColumnsNames.push(element.headerName);
            }
        });
        agGridObject?.api.setGridOption(
            'columnDefs',
            columnDefs.map((columnDef) => {
                if (
                    !(
                        columnDef.headerName &&
                        columnDef.minWidth &&
                        columnDef.maxWidth &&
                        allColumnsNames.length > 0 &&
                        customFlexibleColumns.includes(columnDef.headerName)
                    )
                ) {
                    return columnDef;
                }

                return {
                    ...columnDef,
                    width: getWidth(columnDef.minWidth, columnDef.maxWidth, allColumnsNames),
                };
            }),
        );
    };
}

export function Link(props: { value: string; handleOpen?: () => void }) {
    // use eslint-disable-next-line to avoid the warning - The href attribute is required for an anchor to be keyboard accessible.
    // we don't need href here, use <a>, but not <button> to same ag grid ellipsis behavior
    return (
        // eslint-disable-next-line
        <a
            onClick={props.handleOpen}
            style={{
                color: '#007bb8',
                cursor: 'pointer',
            }}>
            {props.value}
        </a>
    );
}

export function agGridGetRenderedRowsCount(grid: AgGridReact) {
    return grid.api.getRenderedNodes().length;
}

export function agGridGetAllRowsCount(grid: AgGridReact) {
    return grid.api.getDisplayedRowCount();
}

export function getExportColumnKeys(columnDefs: Array<ColumnDef>): Array<string> {
    return columnDefs.filter((p) => p.headerName && p.headerName !== '' && !p.ignoreOnExport).map((p) => p.field || '');
}

export enum AgGridThemes {
    GridThemeV2 = 'grid-theme-v2',
    CpGridTheme = 'cp-grid-theme',
    BcGridTheme = 'bc-grid-theme',
    GridThemeV4 = 'grid-theme-v4',
}

const classNameToDefaultStyle: Record<AgGridThemes, ColDef> = {
    [AgGridThemes.GridThemeV2]: {
        cellStyle: { paddingLeft: 14, paddingRight: 14 },
        headerClass: ['horizontal-padding-14'],
    } as ColDef,
    [AgGridThemes.CpGridTheme]: {
        cellStyle: { paddingLeft: 14, paddingRight: 14 },
        headerClass: ['horizontal-padding-14'],
    } as ColDef,
    [AgGridThemes.BcGridTheme]: {
        cellStyle: { paddingLeft: 14, paddingRight: 14 },
        headerClass: ['horizontal-padding-14'],
    } as ColDef,
    [AgGridThemes.GridThemeV4]: {
        cellStyle: { paddingLeft: 14, paddingRight: 14 },
        headerClass: ['horizontal-padding-14'],
    } as ColDef,
};

function getAgGridThemeFromClassName(className: string): AgGridThemes | undefined {
    const classes = className.split(' ');
    return Object.keys(classNameToDefaultStyle).find((key) => classes.includes(key)) as AgGridThemes | undefined;
}

export function getDefaultStyleFromClassName(className: string): ColDef {
    const key = getAgGridThemeFromClassName(className);

    if (!key) {
        return {};
    }

    return classNameToDefaultStyle[key as AgGridThemes];
}

export function getDefaultColDef({
    className,
    defaultMaxWidth,
}: {
    className: string;
    defaultMaxWidth?: number | null;
}): ColDef {
    const colDef: ColDef = {
        sortable: true,
        filter: true,
        resizable: true,
        autoHeight: true,
        menuTabs: [],
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
        suppressHeaderContextMenu: true,
        ...getDefaultStyleFromClassName(className),
    };

    return {
        ...colDef,
        ...(defaultMaxWidth && { maxWidth: defaultMaxWidth }),
    };
}

export function getClassNameValue(gridTheme?: Array<string> | string) {
    let classNames = ['ag-theme-alpine'];

    if (!gridTheme) {
        return classNames.join(' ');
    }

    if (Array.isArray(gridTheme)) {
        classNames = [...classNames, ...gridTheme];
    } else {
        classNames.push(gridTheme);
    }

    return classNames.join(' ');
}

export function getVisibleColumns(
    gridRef: React.RefObject<AgGridReact<any>>,
    mode: 'fully_visible' | 'partially_visible' = 'fully_visible',
): Array<Column> | null {
    // Get all displayed columns
    const allDisplayedColumns = gridRef?.current?.api.getAllDisplayedColumns();

    // Get the current horizontal pixel range of the visible area
    const horizontalPixelRange = gridRef?.current?.api.getHorizontalPixelRange();

    if (!allDisplayedColumns || !horizontalPixelRange) {
        return null;
    }

    const viewportLeft = horizontalPixelRange.left;
    const viewportRight = horizontalPixelRange.right;

    // Filter columns based on their pixel positions within the viewport range
    const visibleColumns = allDisplayedColumns.filter((column: any) => {
        const columnBorderLeft = column.getLeft();
        const columnBorderRight = columnBorderLeft + column.getActualWidth();

        const isInVisibleArea = (value: number) => {
            const precisionError = 1; // we need to include one more pixel to the viewportRight to be able to assume the column is fully visible after ensureColumnVisible call
            return viewportLeft <= value && viewportRight + precisionError >= value;
        };

        const isLeftColumnBorderVisible = isInVisibleArea(columnBorderLeft);
        const isRightColumnBorderVisible = isInVisibleArea(columnBorderRight);

        return mode === 'fully_visible'
            ? isLeftColumnBorderVisible && isRightColumnBorderVisible
            : isLeftColumnBorderVisible || isRightColumnBorderVisible;
    });

    return visibleColumns;
}

export function getClosestNotVisibleColumn(
    gridRef: React.RefObject<AgGridReact<any>>,
    position: 'next' | 'previous',
    mode: 'fully_visible' | 'partially_visible' = 'fully_visible',
): Column | null {
    const gridRefCurrent = gridRef.current;

    if (!gridRefCurrent) {
        return null;
    }

    const api = gridRefCurrent.api;
    const allColumns = api.getAllDisplayedColumns();

    const visibleColumns = getVisibleColumns(gridRef, mode);
    const fullyVisibleColumns = getVisibleColumns(gridRef, 'fully_visible');

    if (!allColumns || !visibleColumns || visibleColumns.length === 0) {
        return null;
    }

    // Determine the column position to find the next or previous column
    const firstVisibleColumn = visibleColumns[0];
    const lastVisibleColumn = visibleColumns[visibleColumns.length - 1];

    if (position === 'next') {
        // Find the index of the last visible column in the allColumns array
        const lastVisibleIndex = allColumns.indexOf(lastVisibleColumn);

        // Check if there's a column immediately after it
        if (mode === 'partially_visible') {
            return lastVisibleIndex !== -1 && fullyVisibleColumns?.includes(allColumns[lastVisibleIndex]) === false
                ? allColumns[lastVisibleIndex]
                : null;
        }

        return lastVisibleIndex < allColumns.length - 1 ? allColumns[lastVisibleIndex + 1] : null;
    } else if (position === 'previous') {
        // Find the index of the first visible column in the allColumns array
        const firstVisibleIndex = allColumns.indexOf(firstVisibleColumn);

        // Check if there's a column immediately before it
        if (mode === 'partially_visible') {
            return firstVisibleIndex !== -1 && fullyVisibleColumns?.includes(allColumns[firstVisibleIndex]) === false
                ? allColumns[firstVisibleIndex]
                : null;
        }

        return firstVisibleIndex > 0 ? allColumns[firstVisibleIndex - 1] : null;
    }

    throw new Error('Invalid position provided');
}

export function scrollOneColumnTo(direction: 'left' | 'right', gridRef: React.RefObject<AgGridReact<any>>) {
    if (!gridRef.current) {
        return;
    }

    const closestNonVisibleColumn = getClosestNotVisibleColumn(
        gridRef,
        direction === 'right' ? 'next' : 'previous',
        'partially_visible',
    )?.getColId();

    if (!closestNonVisibleColumn) {
        return;
    }

    gridRef.current?.api.ensureColumnVisible(closestNonVisibleColumn, direction === 'right' ? 'end' : 'start');
}

export const keepHeaderSideClassesOnMove =
    ({
        firstColumnCellsClass,
        lastColumnCellsClass,
        applyBothClassesToBothFirstAndLastColumnsForFields,
    }: {
        firstColumnCellsClass: string;
        lastColumnCellsClass: string;
        applyBothClassesToBothFirstAndLastColumnsForFields?: Array<string>;
    }) =>
    (gridContainerRef: React.RefObject<HTMLDivElement>) =>
        function (event: ColumnMovedEvent): void {
            if (!firstColumnCellsClass && !lastColumnCellsClass) {
                return;
            }

            const api = event.api;
            const allColumns = api.getAllDisplayedColumns();
            const target = gridContainerRef.current || document;

            // Get all header elements
            const headerCells = target.querySelectorAll(`.ag-header-cell`);

            // Reset custom classes for all headers
            headerCells.forEach((header) => {
                if (firstColumnCellsClass) {
                    header.classList.remove(firstColumnCellsClass);

                    if (applyBothClassesToBothFirstAndLastColumnsForFields) {
                        header.classList.remove(lastColumnCellsClass);
                    }
                }
                if (lastColumnCellsClass) {
                    header.classList.remove(lastColumnCellsClass);

                    if (applyBothClassesToBothFirstAndLastColumnsForFields) {
                        header.classList.remove(firstColumnCellsClass);
                    }
                }
            });

            // Apply class to the first visible column
            if (allColumns.length > 0) {
                const firstColumn = allColumns[0];
                const firstHeader = target.querySelector(`.ag-header-cell[col-id="${firstColumn.getColId()}"]`);

                if (firstColumnCellsClass && firstHeader) {
                    firstHeader.classList.add(firstColumnCellsClass);

                    if (
                        applyBothClassesToBothFirstAndLastColumnsForFields &&
                        applyBothClassesToBothFirstAndLastColumnsForFields.includes(firstColumn?.getColId() as string)
                    ) {
                        firstHeader.classList.add(lastColumnCellsClass);
                    }
                }

                // Apply class to the last visible column
                const lastColumn = allColumns[allColumns.length - 1];
                const lastHeader = target.querySelector(`.ag-header-cell[col-id="${lastColumn.getColId()}"]`);

                if (lastColumnCellsClass && lastHeader) {
                    lastHeader.classList.add(lastColumnCellsClass);

                    if (
                        applyBothClassesToBothFirstAndLastColumnsForFields &&
                        applyBothClassesToBothFirstAndLastColumnsForFields.includes(lastColumn?.getColId() as string)
                    ) {
                        lastHeader.classList.add(firstColumnCellsClass);
                    }
                }
            }

            // Refresh the grid cells to keep everything consistent
            event.api.refreshCells({ force: true });
        };

export const keepNoSidePaddingsOnMove = keepHeaderSideClassesOnMove({
    firstColumnCellsClass: 'no-left-padding',
    lastColumnCellsClass: 'no-right-padding',
});

export const keepNoLeftPaddingOnMove = keepHeaderSideClassesOnMove({
    firstColumnCellsClass: 'no-left-padding',
    lastColumnCellsClass: '',
});

export const isFirstColumn: (params: any) => boolean = (params: any) => {
    const allDisplayedColumns = params.api.getAllDisplayedColumns();
    const columnIndex = allDisplayedColumns.indexOf(params.column);
    return columnIndex === 0;
};

export const isLastColumn: (params: any) => boolean = (params: any) => {
    const allDisplayedColumns = params.api.getAllDisplayedColumns();
    const columnIndex = allDisplayedColumns.indexOf(params.column);
    return columnIndex === allDisplayedColumns.length - 1;
};

export const keepNoSidePaddingsCellClassRules = {
    'no-left-padding': isFirstColumn,
    'no-right-padding': isLastColumn,
};

export const keepNoLeftPaddingCellClassRules = {
    'no-left-padding': isFirstColumn,
};

interface SetWithDifference<T> extends Set<T> {
    difference<U>(other: ReadonlySet<U>): Set<T>;
}

type GetColumnDefsEqualComparator = (compareBasedOn?: {
    fields: boolean;
    columnsOrder: boolean;
    columnsVisibility: boolean;
    columnsWidths: boolean;
}) => NonNullable<AgGridProps['checkColumnDefsEqual']>;

export const getColumnDefsEqualComparator: GetColumnDefsEqualComparator =
    (compareBasedOn) => (newColumnDefs, currentColumnDefs) => {
        if (compareBasedOn === undefined) {
            compareBasedOn = {
                fields: true,
                columnsOrder: true,
                columnsVisibility: true,
                columnsWidths: true,
            };
        }

        if (!(currentColumnDefs && newColumnDefs && currentColumnDefs.length > 0 && newColumnDefs.length > 0)) {
            return false;
        }

        if (compareBasedOn.fields) {
            const newColumnFields = new Set(newColumnDefs.map((columnDef) => columnDef.field)) as SetWithDifference<
                string | undefined
            >;
            const currentColumnFields = new Set(
                (currentColumnDefs as Array<ColDef>)?.map((columnDef) => columnDef.field),
            ) as SetWithDifference<string | undefined>;

            const fieldsCompareResult =
                newColumnFields.difference(currentColumnFields).size === 0 &&
                currentColumnFields.difference(newColumnFields).size === 0;

            if (!fieldsCompareResult) {
                return false;
            }
        }

        if (compareBasedOn.columnsOrder) {
            const columnsOrderCompareResult = newColumnDefs.every(
                (columnDef, index) => columnDef.field === currentColumnDefs[index].field,
            );

            if (!columnsOrderCompareResult) {
                return false;
            }
        }

        if (compareBasedOn.columnsVisibility) {
            const columnsVisibilityCompareResult = newColumnDefs.every((columnDef) => {
                const currentColumnDef = currentColumnDefs.find(
                    (currentColumnDef) => getColumnId(currentColumnDef) === getColumnId(columnDef),
                );

                return currentColumnDef?.hide === columnDef.hide;
            });

            if (!columnsVisibilityCompareResult) {
                return false;
            }
        }

        if (compareBasedOn.columnsWidths) {
            const columnsWidthsCompareResult = newColumnDefs.every((newColumnDef) => {
                const currentColumnDef = currentColumnDefs.find(
                    (currentColumnDef) => getColumnId(currentColumnDef) === getColumnId(newColumnDef),
                );

                return currentColumnDef?.width === newColumnDef?.width && currentColumnDef?.flex === newColumnDef?.flex;
            });

            if (!columnsWidthsCompareResult) {
                return false;
            }
        }

        return true;
    };

export const handleSortOrderIndicators = ({
    event,
    orderBy,
    sortDirection,
}: {
    event: ModelUpdatedEvent<any, any>;
    orderBy?: string;
    sortDirection?: 'asc' | 'desc';
}) => {
    // handle ag grid columns sorting icon (only icon, the sorting logic supposed to be handled on backend side)
    const api = event?.api;
    if (!api || !event?.api) {
        return;
    }

    const columnsState = api.getColumnState();

    // handle apply sorting from the API request to the ag grid
    if (orderBy && sortDirection) {
        const sortColumn = columnsState?.find((column) => column.colId === orderBy);

        if (!sortColumn) {
            return; // if for some reason the api request contains sorting by column not present in the grid, don't apply sorting in the grid
        }

        if (sortColumn.sort === sortDirection) {
            return; // if grid column already sorted by the same direction, don't apply sorting in the grid
        }

        // apply sorting for this specific one column (and reset sorting for other column is there is any)
        api.applyColumnState({
            state: columnsState.map((column) => {
                return {
                    ...column,
                    sort: column.colId === orderBy ? sortDirection : null,
                };
            }),
            applyOrder: true,
        });
        return;
    }

    const sortColumn = columnsState?.find((column) => column.sort ?? true);

    if (!sortColumn) {
        return; // if no sorting options in the API request, and no sorting in the ag grid columns, don't remove sorting from the grid
    }

    // handle reset sorting from API request to ag grid
    api.applyColumnState({
        state: columnsState.map((column) => {
            return {
                ...column,
                sort: null,
            };
        }),
        applyOrder: true,
    });
};

export const handleSortIndicatorClick = ({
    event,
    wasSortingChangedRef,
    updateSearchByParams,
    onSetSortOptions,
    onResetSortOptions,
}: {
    event: SortChangedEvent;
    wasSortingChangedRef?: MutableRefObject<boolean>;
    updateSearchByParams: Dispatch<SearchByParams>;
    onSetSortOptions?: (event: SortChangedEvent, sortOptions: { colId: string; sort: 'asc' | 'desc' }) => void;
    onResetSortOptions?: (event: SortChangedEvent) => void;
}) => {
    // handle only sorting by user click on header
    if (!event.api || !['columnMenu', 'uiColumnSorted'].includes(event.source)) {
        return;
    }

    const columnsState = event.api.getColumnState();
    const sortColumn = columnsState?.find((column) => column.sort);

    if (wasSortingChangedRef) {
        wasSortingChangedRef.current = true;
    }

    if (sortColumn) {
        const sortOptions = {
            orderBy: sortColumn.colId,
            sortDirection: sortColumn.sort as 'asc' | 'desc',
        };
        onSetSortOptions?.(event, {
            colId: sortOptions.orderBy,
            sort: sortOptions.sortDirection,
        });
        // set sorting options for API
        updateSearchByParams(sortOptions);
    } else {
        onResetSortOptions?.(event);
        // reset sorting options for API
        updateSearchByParams({
            orderBy: undefined,
            sortDirection: undefined,
        });
    }
};
