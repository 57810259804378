import {
    useFiltersForm,
    UseFiltersFormInputProps,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/hooks/useFiltersForm';
import { useMemo } from 'react';
import { FiltersButtonWithModal } from './FiltersButtonWithModal';
import { FiltersSection } from './FiltersSection';
import { getFiltersSectionJsx } from './getFiltersSectionJsx';
import { getModalFiltersJsx } from './getModalFiltersJsx';
import { FiltersFormProps } from './shared';
import { ScreenerChipThemeOutline } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsPanelRowStyle';

export function FiltersForm({ filtersConfig, isMobileVariant, ...props }: FiltersFormProps) {
    const getFiltersJsx: UseFiltersFormInputProps['getFiltersJsx'] = useMemo(() => {
        if (isMobileVariant) {
            return getModalFiltersJsx(filtersConfig);
        }

        return getFiltersSectionJsx(filtersConfig);
    }, [isMobileVariant, filtersConfig]);

    const { filtersFormJsx, filtersChipPanelJsx, submitHandler, control } = useFiltersForm({
        ...props,
        getFiltersJsx,
        chipStyles: {
            containerSx: { padding: isMobileVariant ? '18px 14px 15px 14px' : '0', maxHeight: 'unset' },
            onChipItemsExistSxProps: { marginBottom: isMobileVariant ? '0px' : '17px' },
        },
        skeletonContainerSx: {
            padding: '0',
            marginBottom: '24px',
            ...(isMobileVariant ? { padding: '24px 14px' } : {}),
        },
        formStyle: { width: '100%', marginBottom: isMobileVariant ? '0px' : '24px' },
        useSubmitInFilters: true,
        chipTheme: isMobileVariant ? ScreenerChipThemeOutline : undefined,
    });

    if (isMobileVariant) {
        return (
            <FiltersButtonWithModal
                filtersFormJsx={filtersFormJsx}
                filtersChipPanelJsx={filtersChipPanelJsx}
                externalChipItems={props.externalChipItems}
                submitHandler={submitHandler}
                control={control}
                externalFormStateStorage={props.externalFormStateStorage}
                watchlistName = {filtersConfig.watchlistProps?.componentRef?.current?.watchlistName}
            />
        );
    }

    return <FiltersSection filtersFormJsx={filtersFormJsx} filtersChipPanelJsx={filtersChipPanelJsx} />;
}
