import { ETFLinkButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton';
import {
    PillsRowVariant1,
    PillsRowVariant1Item,
} from '@cfra-nextgen-frontend/shared/src/components/Pill/PillsRowVariant1';
import { ReadMoreVariantLink } from '@cfra-nextgen-frontend/shared/src/components/ReadMore/ReadMoreVariantLink';
import { GetOptionsContainer } from '@cfra-nextgen-frontend/shared/src/components/TypeSearch/types';
import {
    TypographyStyle4,
    TypographyStyle6,
    TypographyStyle7,
    TypographyStyle8,
    TypographyStyle9,
} from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { getEllipsisStyles } from '@cfra-nextgen-frontend/shared/src/components/Typography/utils';
import { useCheckOverflow } from '@cfra-nextgen-frontend/shared/src/hooks/useCheckOverflow';
import { useLinkGetter, UseLinkGetterOutputProps } from '@cfra-nextgen-frontend/shared/src/hooks/useLinkGetter';
import { useTextCutter } from '@cfra-nextgen-frontend/shared/src/hooks/useTextCutter';
import { getCursorVariant1 } from '@cfra-nextgen-frontend/shared/src/utils/cursor';
import { Box, Grid, GridProps, SxProps } from '@mui/material';
import React, { forwardRef, useCallback, useMemo } from 'react';

const oneLineEllipsisStyles = getEllipsisStyles(1);

type ItemVariant3Props<T> = {
    topText: string;
    topSubText: string;
    middleTitle: string;
    middleSubText: string;
    bottomTextItems: Array<string>;
    bottomPillItems: Array<PillsRowVariant1Item>;
    bottomPillItemsThreshold: number;
    maxMiddleSubTextLength: number;
    useLinkGetterParams: Parameters<typeof useLinkGetter<T>>;
    containerStyles?: SxProps;
    handleOuterSetRequestParamsProps?: (
        setRequestParamsProps: UseLinkGetterOutputProps<T>['setRequestParamsProps'],
    ) => void;
    topTextRightSlot?: React.ReactNode;
    rootItemProps?: GridProps;
    middleContainerStyle?: SxProps;
};

export function ItemVariant3<T>({
    topText,
    topSubText,
    middleTitle,
    middleSubText,
    bottomTextItems,
    bottomPillItems,
    bottomPillItemsThreshold,
    maxMiddleSubTextLength,
    useLinkGetterParams,
    containerStyles = {},
    handleOuterSetRequestParamsProps,
    topTextRightSlot,
    rootItemProps,
    middleContainerStyle,
}: ItemVariant3Props<T>) {
    const { setRequestParamsProps, isLoading, isError } = useLinkGetter<T>(...useLinkGetterParams);

    const cursor = useMemo(() => getCursorVariant1(isLoading, isError), [isLoading, isError]);

    const { lastIndex, cutNextWord } = useTextCutter({
        text: middleSubText,
        maxTextLength: maxMiddleSubTextLength,
    });

    const { setContainer, setElement } = useCheckOverflow({
        onOverflowCallback: () => cutNextWord(),
    });

    const getLink = useCallback(
        (handleOpen?: () => void) => {
            return (
                <Box
                    ref={setElement}
                    sx={{
                        display: 'inline',
                    }}>
                    <ETFLinkButton
                        onClick={handleOpen}
                        text={'... more'}
                        sx={{
                            minWidth: '0px',
                            height: '19px',
                            '&:hover': {
                                cursor: cursor,
                            },
                        }}
                    />
                </Box>
            );
        },
        [setElement, cursor],
    );

    const handleLinkClick = useCallback(() => {
        handleOuterSetRequestParamsProps?.(setRequestParamsProps);
    }, [handleOuterSetRequestParamsProps, setRequestParamsProps]);

    return (
        <Grid item {...rootItemProps} sx={containerStyles}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <TypographyStyle4 sx={oneLineEllipsisStyles}>{topText}</TypographyStyle4>
                {topTextRightSlot}
            </Box>

            <TypographyStyle8 sx={{ lineHeight: 1, paddingTop: '3.75px', paddingBottom: '7px' }}>
                {topSubText}
            </TypographyStyle8>
            <Box ref={setContainer} sx={middleContainerStyle}>
                <TypographyStyle6
                    onClick={handleLinkClick}
                    sx={{
                        '&:hover': {
                            color: '#3078B5',
                            cursor: cursor,
                        },
                        lineHeight: '23.5px',
                    }}>
                    {middleTitle}
                </TypographyStyle6>
                <TypographyStyle7
                    component='div'
                    sx={{
                        marginBottom: '4px',
                        lineHeight: '22px',
                    }}>
                    {
                        <ReadMoreVariantLink
                            text={middleSubText}
                            textLengthToShowReadMore={lastIndex}
                            outerLink={getLink(handleLinkClick)}
                        />
                    }
                </TypographyStyle7>
            </Box>
            <TypographyStyle9 sx={{ paddingTop: '7px', lineHeight: 1, ...oneLineEllipsisStyles }}>
                {bottomTextItems.join(', ')}
            </TypographyStyle9>
            <Grid container gap={'5px'} sx={{ paddingTop: '10px' }}>
                <PillsRowVariant1 items={bottomPillItems} threshold={bottomPillItemsThreshold} />
            </Grid>
        </Grid>
    );
}

type ItemVariant3ContainerProps = {
    containerStyles?: SxProps;
    containerWrapperSx?: SxProps;
    children: React.ReactNode;
    otherContainerProps?: GridProps;
    setOuterContainerRef?: (node: HTMLDivElement) => void;
};

export const ItemVariant3Container = forwardRef<HTMLDivElement, ItemVariant3ContainerProps>(
    ({ containerStyles, children, otherContainerProps, setOuterContainerRef, containerWrapperSx }, ref) => (
        <Box ref={setOuterContainerRef} sx={containerWrapperSx}>
            <Grid
                ref={ref}
                key='gridView'
                container
                gap={4.4}
                justifyContent='center'
                sx={containerStyles}
                {...otherContainerProps}>
                {children}
            </Grid>
        </Box>
    ),
);
