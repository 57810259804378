import { Box, SxProps } from '@mui/material';
import { Toggle } from '../../Toggle/ToggleVariant1';
import { useState, useCallback, useMemo } from 'react';
import { TitleWithLink, TitleWithLinkProps } from '../TitleWithLink';

type Results1ColumnProps = {
    options: Record<string, React.ReactNode>;
    toggleContainerSx?: SxProps;
    titleWithLinkProps?: Omit<TitleWithLinkProps, 'linkProps'>;
    topLinkParams?: {
        text: string;
        pathname: string;
    };
    inputValue?: string;
    onClickOutsideCallback?: () => void;
};

export function Results1Column({
    options,
    toggleContainerSx,
    titleWithLinkProps,
    topLinkParams,
    inputValue,
    onClickOutsideCallback,
}: Results1ColumnProps) {
    const [selectedOption, setSelectedOption] = useState<number>(0);

    const onChange = useCallback((optionIndex: number) => {
        setSelectedOption(optionIndex);
    }, []);

    const optionsKeys = useMemo(() => {
        return Object.keys(options);
    }, [options]);

    return (
        <>
            {titleWithLinkProps && (
                <TitleWithLink
                    linkProps={{
                        linkText: topLinkParams?.text || '',
                        onClick: () => onClickOutsideCallback?.(),
                        state: { searchTerm: inputValue },
                        to: {
                            pathname: topLinkParams?.pathname || '',
                        },
                    }}
                    {...titleWithLinkProps}
                />
            )}
            <Box sx={toggleContainerSx}>
                <Toggle defaultSelectionIndex={selectedOption} options={optionsKeys} onChange={onChange} />
            </Box>
            {Object.values(options)[selectedOption]}
        </>
    );
}
