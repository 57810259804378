import { ReactComponent as CheckBoxCheckedIcon } from '@cfra-nextgen-frontend/shared/src/assets/icons/checkbox-checked.svg';
import { ReactComponent as CheckBoxUncheckedIcon } from '@cfra-nextgen-frontend/shared/src/assets/icons/checkbox-unchecked.svg';
import { CheckboxProps, createTheme, Checkbox as MUICheckbox, SxProps, ThemeProvider } from '@mui/material';
import React from 'react';
import { CustomSvgIcon } from '../Icon/CustomSvgIcon';

type CheckboxAdvancedProps = CheckboxProps & {
        // expects a React component that is an SVG element
        checkedIconComponent?: React.FC<React.SVGProps<SVGSVGElement>>;
        // expects a React component that is an SVG element
        uncheckedIconComponent?: React.FC<React.SVGProps<SVGSVGElement>>;
        // width and height of the icon
        size?: number;
        checkedViewBoxSize?: number;
        uncheckedViewBoxSize?: number;
        checkedIconSx?: SxProps;
        uncheckedIconSx?: SxProps;
    };

export const CheckboxAdvanced: React.FC<CheckboxAdvancedProps> = ({
    checkedIconComponent = CheckBoxCheckedIcon,
    uncheckedIconComponent = CheckBoxUncheckedIcon,
    size = 18,
    checkedViewBoxSize = size,
    uncheckedViewBoxSize = size,
    checkedIconSx = {
        fill: 'white',
    },
    uncheckedIconSx = checkedIconSx,
    onChange,
    checked,
}) => {
    return (
        <ThemeProvider
            theme={createTheme({
                components: {
                    MuiCheckbox: {
                        styleOverrides: {
                            root: {
                                padding: '0px',
                                width: `${size}px`,
                                height: `${size}px`,
                            },
                        },
                    },
                    MuiSvgIcon: {
                        styleOverrides: {
                            root: {
                                width: `${size}px`,
                                height: `${size}px`,
                            },
                        },
                    },
                },
            })}>
            <MUICheckbox
                onChange={onChange}
                checked={checked}
                checkedIcon={
                    <CustomSvgIcon
                        component={checkedIconComponent}
                        viewBox={`0 0 ${checkedViewBoxSize} ${checkedViewBoxSize}`}
                        width={checkedViewBoxSize}
                        height={checkedViewBoxSize}
                        sx={checkedIconSx}
                    />
                }
                icon={
                    <CustomSvgIcon
                        component={uncheckedIconComponent}
                        viewBox={`0 0 ${uncheckedViewBoxSize} ${uncheckedViewBoxSize}`}
                        width={uncheckedViewBoxSize}
                        height={uncheckedViewBoxSize}
                        sx={uncheckedIconSx}
                    />
                }
            />
        </ThemeProvider>
    );
};
