import settingsIcon from '@cfra-nextgen-frontend/shared/src/assets/icons/settings.svg';
import { ETFLinkButton } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ETFLinkButton';
import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout';
import { Views } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFButtonsPannel/ViewsPanel';
import { ResultsContextProvider } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/ResultsContext';
import { useUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import {
    IdsAndSelectionsPreferencesActions,
    IdsAndSelectionsPreferencesEachElement,
    IdsAndSelectionsTypeExtension,
    PreferenceType,
} from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { invalidateQueriesByKey } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { getSavedItemsByIdConfig } from '@cfra-nextgen-frontend/shared/src/utils/userSavedItem';
import { Box, Stack } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BcLabel } from 'components/BcLabel/BcLabel';
import { homePageTheme } from 'components/themes/theme';
import { ManageWatchlist, WatchlistCompanyIds } from 'components/Watchlist/ManageWatchlist';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { horizontalPaddingInSu } from 'utils/lookAndFeel';
import { UserPreferences, UserPreferencesSelectors, useUserPreferencesProps } from 'utils/preferences';
import { BiggestConcernsCard } from './BiggestConcernsCard';
import { CompanyReports, ReportsLoadingContainerCard } from './CompanyReports';
import { DailyPacketCard } from './DailyPacketCard';
import { IndustryResearch } from './IndustryResearch';
import { RecentViews } from './RecentViews';
import { TrendingCard } from './TrendingCard';
const leftColumnWidthInSu = 7.895;

type CompanyReportsRefType = {
    selectedView: Views;
};

const userPreferencesGetterParams = {
    preferenceType: PreferenceType.IdsAndSelectionsPreferences,
};

const LastViewedWatchlistIdSelector = UserPreferencesSelectors[UserPreferences.HomePageCommon];
const CompanyReportsResearchTypeIdsSelector = UserPreferencesSelectors[UserPreferences.HomePageCompanyReports];
const IndustryResearchGicsCoreSelector = UserPreferencesSelectors[UserPreferences.HomePageIndustryResearch];

export function HomePage() {
    const navigate = useNavigate();
    const companyReportsRef = useRef<CompanyReportsRefType>({ selectedView: Views.GridView });
    const { getUserPreferences, setUserPreferences } = useUserPreferences(useUserPreferencesProps);

    const researchTypeIds = useMemo(() => {
        return getUserPreferences?.<IdsAndSelectionsPreferencesEachElement>({
            preferenceType: userPreferencesGetterParams.preferenceType,
            selector: CompanyReportsResearchTypeIdsSelector,
        })?.researchTypeIds;
    }, [getUserPreferences]);

    const setResearchTypeIds = useCallback(
        (researchTypeIds?: Array<number>) => {
            setUserPreferences?.<keyof typeof IdsAndSelectionsPreferencesActions, IdsAndSelectionsTypeExtension>(
                userPreferencesGetterParams.preferenceType,
                {
                    ...CompanyReportsResearchTypeIdsSelector,
                    action: IdsAndSelectionsPreferencesActions.SetResearchTypeIds,
                    researchTypeIds: researchTypeIds,
                },
            );
        },
        [setUserPreferences],
    );

    const lastViewedWatchlistId = useMemo(() => {
        return getUserPreferences?.<IdsAndSelectionsPreferencesEachElement>({
            preferenceType: userPreferencesGetterParams.preferenceType,
            selector: LastViewedWatchlistIdSelector,
        })?.lastViewedWatchlistId;
    }, [getUserPreferences]);

    const setLastViewedWatchlistId = useCallback(
        (lastViewedWatchlistId?: number) => {
            setUserPreferences?.<keyof typeof IdsAndSelectionsPreferencesActions, IdsAndSelectionsTypeExtension>(
                userPreferencesGetterParams.preferenceType,
                {
                    each: [
                        {
                            ...LastViewedWatchlistIdSelector,
                            action: IdsAndSelectionsPreferencesActions.SetLastViewedWatchlistId,
                            lastViewedWatchlistId: lastViewedWatchlistId,
                        },
                        {
                            ...CompanyReportsResearchTypeIdsSelector,
                            action: IdsAndSelectionsPreferencesActions.SetResearchTypeIds,
                            researchTypeIds: [],
                        },
                        {
                            ...IndustryResearchGicsCoreSelector,
                            action: IdsAndSelectionsPreferencesActions.SetGicsSectors,
                            gicsSectors: [],
                        },
                    ],
                },
            );
        },
        [setUserPreferences],
    );

    const gicsSectors = useMemo(() => {
        return getUserPreferences?.<IdsAndSelectionsPreferencesEachElement>({
            preferenceType: userPreferencesGetterParams.preferenceType,
            selector: IndustryResearchGicsCoreSelector,
        })?.gicsSectors;
    }, [getUserPreferences]);

    const setGicsSectors = useCallback(
        (gicsSectors?: Array<string>) => {
            setUserPreferences?.<keyof typeof IdsAndSelectionsPreferencesActions, IdsAndSelectionsTypeExtension>(
                userPreferencesGetterParams.preferenceType,
                {
                    ...IndustryResearchGicsCoreSelector,
                    action: IdsAndSelectionsPreferencesActions.SetGicsSectors,
                    gicsSectors,
                },
            );
        },
        [setUserPreferences],
    );

    const [indResearchGics, setIndResearchGics] = useState<string[]>();
    const [watchlistCompanyIds, setWatchlistCompanyIds] = useState<WatchlistCompanyIds>();
    const maxWidth600 = useMediaQuery('(max-width:600px)');
    const aboveMd = useMediaQuery(homePageTheme.breakpoints.up('md'));

    useEffect(() => {
        invalidateQueriesByKey(getSavedItemsByIdConfig.queryKeyFirstElement);
    }, []);

    const handleManageWatchlistClick = useCallback(() => {
        navigate('/watchlist', { state: { homepageViewedWatchlistId: lastViewedWatchlistId } });
    }, [navigate, lastViewedWatchlistId]);

    const handleOnViewChange = useCallback((view: Views) => {
        companyReportsRef.current.selectedView = view;
    }, []);

    return (
        <ThemeProvider theme={homePageTheme}>
            <ResultsContextProvider>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '12px' }}>
                    <ManageWatchlist
                        setIndResearchGics={setIndResearchGics}
                        watchlistCompanyIds={watchlistCompanyIds}
                        setWatchlistCompanyIds={setWatchlistCompanyIds}
                        lastViewedWatchlistId={lastViewedWatchlistId}
                        useCreatedWatchlistId
                        setLastViewedWatchlistId={setLastViewedWatchlistId}
                    />
                    <ETFLinkButton
                        text={maxWidth600 ? '' : 'Manage Watchlist'}
                        onClick={handleManageWatchlistClick}
                        sx={{
                            '&:hover': {
                                background: 'transparent',
                            },
                            '& .MuiButton-endIcon': {
                                marginLeft: maxWidth600 ? '0px' : '8px',
                            },
                        }}
                        endIcon={<Box component='img' src={settingsIcon} width='100%' height='auto' />}
                    />
                </Box>

                <Grid container spacing={horizontalPaddingInSu}>
                    <Grid
                        container
                        item
                        display={'flex'}
                        flexWrap={{ xs: 'wrap', sm: 'wrap-reverse' }}
                        spacing={4}
                        flexDirection={{ sm: 'row-reverse', md: 'row' }}>
                        <Grid item md={leftColumnWidthInSu} xs={12} paddingTop={{ xs: '36px', sm: '0px' }}>
                            {watchlistCompanyIds !== undefined ? (
                                <CompanyReports
                                    key={'companyReportsRef'}
                                    onViewChange={handleOnViewChange}
                                    researchTypeIds={researchTypeIds}
                                    setResearchTypeIds={setResearchTypeIds}
                                    watchlistCompanyIds={watchlistCompanyIds}
                                    selectedView={companyReportsRef.current.selectedView}
                                />
                            ) : (
                                <ReportsLoadingContainerCard />
                            )}
                        </Grid>
                        {!aboveMd && (
                            <Grid item xs={12} sm={6} paddingTop={{ xs: '36px', sm: '0px' }}>
                                <BiggestConcernsCard />
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6} md={12 - leftColumnWidthInSu}>
                            <TrendingCard />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={leftColumnWidthInSu}>
                        <Stack spacing={horizontalPaddingInSu} width='100%' height='100%'>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                <Box
                                    sx={{
                                        marginBottom: '10px',
                                        marginTop: '-13px',
                                    }}>
                                    <BcLabel />
                                </Box>
                                {indResearchGics !== undefined ? (
                                    <IndustryResearch
                                        gicsCode={indResearchGics}
                                        gicsSectors={gicsSectors}
                                        setGicsSectors={setGicsSectors}
                                    />
                                ) : (
                                    <ReportsLoadingContainerCard />
                                )}
                            </Box>
                            <Box width='100%'>
                                <Grid container spacing={horizontalPaddingInSu}>
                                    <Grid item xs={12} sm={6}>
                                        <RecentViews />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <DailyPacketCard />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Stack>
                    </Grid>

                    {aboveMd && (
                        <Grid item md={12 - leftColumnWidthInSu}>
                            <BiggestConcernsCard />
                        </Grid>
                    )}
                </Grid>
            </ResultsContextProvider>
        </ThemeProvider>
    );
}
