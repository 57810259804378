import { CommonFormComponentProps } from '@cfra-nextgen-frontend/shared/src/components/Form/types/form';
import { Controller } from 'react-hook-form';
import { FormControlLabel, Radio } from '@mui/material';
import { RadioGroup } from '../Radio/RadioGroup';

type FormRadioGroupProps = {
    options: { key: string | number, value: string }[]
    defaultValue?: string | number;
} & CommonFormComponentProps;

export default function FormRadioGroup(props: FormRadioGroupProps) {
    
    return (
        <Controller
            name={props.name}
            control={props.control}
            render={({ field }) => {
                let defaultValue;
                defaultValue = props?.getValues?.(props.name);
                return (
                    <RadioGroup 
                        value={defaultValue || props.defaultValue}
                        onChange={(_, data) => {
                            field.onChange(data);
                            props.submitHandler?.(data as any);
                        }}
                    >
                        {
                            props.options.map(
                                (option) => 
                                    <FormControlLabel key={option.key} value={option.key} control={<Radio />} label={option.value} />
                            )
                        }
                    </RadioGroup>
                );
            }}
        />
    );
}
