export const gridItemStyle4columns = {
    // show 1 column on the range [0px;669px] screen width
    // show 2 columns on the range [670px;1199px] screen width
    // show 3 and more columns automatically starting from 1200px screen width

    // width when 4 columns
    width: '304.5px',
    minWidth: '304.5px',

    // no min width for the smallest screens
    '@media (max-width: 380.5px)': {
        width: 'calc(100% - 36px)',
        minWidth: 'calc(100% - 36px)',
    },

    // make two columns starting from 670 px
    '@media (min-width: 669px) and (max-width: 765px)': {
        minWidth: '257px',
    },

    // keep 2 columns up to 1199 px including
    '@media (min-width: 1106px) and (max-width: 1199px)': {
        minWidth: '336.5px',
    },
};
