import { ReactComponent as OrderIcon } from '@cfra-nextgen-frontend/shared/src/assets/icons/order.svg';
import {
    CFRASelectVariant3,
    CFRASelectVariant3ThemeOptions,
} from '@cfra-nextgen-frontend/shared/src/components/CFRASelect/CFRASelectVariant3';
import { Box, ButtonBase, createTheme, SelectChangeEvent } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { SortOptions } from 'utils/enums';

function Wrapper({ children, useButtonBase }: { children: React.ReactNode; useButtonBase: boolean }) {
    if (!useButtonBase) {
        return <>{children}</>;
    }

    return (
        <ButtonBase
            key='sortOrderButtonBase'
            sx={{
                padding: '3px',
                margin: '0px',
                position: 'relative',
                top: '0px',
                borderRadius: '5px',
                '&:hover': {
                    backgroundColor: '#0000000A',
                },
            }}>
            {children}
        </ButtonBase>
    );
}

export function SortOrder({
    variant,
    handleSelectionChange,
    selectedSortOption,
    defaultSelectedSortOption,
}: {
    variant: 'short' | 'normal';
    handleSelectionChange: (event: SelectChangeEvent<unknown>, child: React.ReactNode) => void;
    selectedSortOption?: SortOptions;
    defaultSelectedSortOption?: SortOptions;
}) {
    const isShortVariant = useMemo(() => variant === 'short', [variant]);

    const theme = useMemo(() => {
        const options = deepmerge(cloneDeep(CFRASelectVariant3ThemeOptions), {
            components: {
                MuiButtonBase: {
                    defaultProps: {
                        disableRipple: false,
                    },
                },
                MuiSelect: {
                    styleOverrides: {
                        select: {
                            width: '20px !important',
                            height: '20px !important',
                        },
                        icon: {
                            right: '0px',
                            top: '3px',
                        },
                    },
                },
                MuiInputBase: {
                    styleOverrides: {
                        root: {
                            width: 'unset !important',
                            height: 'unset !important',
                        },
                    },
                },
            },
        });

        return createTheme(options);
    }, []);

    return (
        <Box sx={isShortVariant ? undefined : { marginTop: '1px', marginLeft: '5px' }}>
            <Wrapper key='wrapper' useButtonBase={isShortVariant}>
                <CFRASelectVariant3
                    sx={
                        isShortVariant
                            ? {
                                  // prevent icon rotation
                                  '& .MuiSelect-icon': {
                                      transform: 'none !important',
                                      stroke: '#555555',
                                      ...(selectedSortOption !== defaultSelectedSortOption
                                          ? {
                                                stroke: '#007AB9',
                                            }
                                          : {}),
                                  },
                              }
                            : undefined
                    }
                    showOnlyIcon={isShortVariant}
                    iconComponent={isShortVariant ? OrderIcon : undefined}
                    externalThemeOptions={isShortVariant ? theme : CFRASelectVariant3ThemeOptions}
                    selectItems={Object.values(SortOptions)}
                    handleSelectionChange={handleSelectionChange}
                    currentSelection={selectedSortOption}
                    placeholder={isShortVariant ? undefined : 'Selected Sort'}
                    tooltipContainerStyles={
                        isShortVariant
                            ? {
                                  width: '20px',
                                  height: '20px',
                              }
                            : undefined
                    }
                />
            </Wrapper>
        </Box>
    );
}
