import { GenericSelector } from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { ApplicationType } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { PageNames } from './enums';
import { UsePreferenceType } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import { Views } from '@cfra-nextgen-frontend/shared/src/components/layout/ETFButtonsPannel/ViewsPanel';

export const useUserPreferencesProps: UsePreferenceType = {
    applicationType: ApplicationType.InstitutionalPortal,
};

export const enum UserPreferences {
    WatchlistSelectLastViewedId = 'watchlistSelectLastViewedId',
    HomePageCommon = 'homePageCommon',
    HomePageCompanyReports = 'homePageCompanyReports',
    HomePageIndustryResearch = 'homePageIndustryResearch',
    ResearchHubSearchResultsTableView = 'researchHubSearchResultsTableView',
    ResearchCompanySearchResultsTableView = 'researchCompanySearchResultsTableView',
    ResearchIndustrySearchResultsTableView = 'researchIndustrySearchResultsTableView',
    BespokeAdminSearchResultsTableView = 'bespokeAdminSearchResultsTableView',
    BespokeEdgeSearchResultsTableView = 'bespokeEdgeSearchResultsTableView',
    LegalEdgeSearchResultsTableView = 'legalEdgeSearchResultsTableView',
    WatchlistResultsTable = 'watchlistResultsTable',
}

export enum Locations {
    CompanyReports = 'Company Reports',
    IndustryResearch = 'Industry Research',
    SearchResults = 'Search Results',
}

enum FeatureNames {
    WatchlistSelectLastViewedId = 'watchlistSelectLastViewedId',
}

export type InstitutionalSelector = GenericSelector<{
    pageName?: PageNames;
    location?: Locations;
    featureName?: FeatureNames;
    view?: Views;
}>;

export const UserPreferencesSelectors: Record<UserPreferences, InstitutionalSelector> = {
    [UserPreferences.WatchlistSelectLastViewedId]: {
        featureName: FeatureNames.WatchlistSelectLastViewedId,
    },
    [UserPreferences.HomePageCommon]: {
        pageName: PageNames.Home,
    },
    [UserPreferences.HomePageCompanyReports]: {
        pageName: PageNames.Home,
        location: Locations.CompanyReports,
    },
    [UserPreferences.HomePageIndustryResearch]: {
        pageName: PageNames.Home,
        location: Locations.IndustryResearch,
    },
    [UserPreferences.ResearchHubSearchResultsTableView]: {
        pageName: PageNames.ResearchHub,
        location: Locations.SearchResults,
        view: Views.TableView,
    },
    [UserPreferences.ResearchCompanySearchResultsTableView]: {
        pageName: PageNames.AccountingLensCompanyResearch,
        location: Locations.SearchResults,
        view: Views.TableView,
    },
    [UserPreferences.ResearchIndustrySearchResultsTableView]: {
        pageName: PageNames.AccountingLensIndustryResearch,
        location: Locations.SearchResults,
        view: Views.TableView,
    },
    [UserPreferences.BespokeAdminSearchResultsTableView]: {
        pageName: PageNames.BespokeAdmin,
        location: Locations.SearchResults,
        view: Views.TableView,
    },
    [UserPreferences.BespokeEdgeSearchResultsTableView]: {
        pageName: PageNames.BespokeEdge,
        location: Locations.SearchResults,
        view: Views.TableView,
    },
    [UserPreferences.LegalEdgeSearchResultsTableView]: {
        pageName: PageNames.LegalEdge,
        location: Locations.SearchResults,
        view: Views.TableView,
    },
    [UserPreferences.WatchlistResultsTable]: {
        pageName: PageNames.Watchlists,
    },
};
