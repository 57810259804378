export const openExternalLink = (url: string, target: string = '_blank') => {
    window.open(url, target);
};

export function loadScript(doc: Document, src: string, type: string = 'text/javascript'): void {
    try {
        if (!src) {
            throw new Error('Script source cannot be empty');
        }

        if (!doc.head) {
            throw new Error('Document head not found');
        }

        const script = doc.createElement('script');
        if (!script) {
            throw new Error('Failed to create script element');
        }
        
        script.type = type;
        script.src = src;
        
        doc.head.appendChild(script);
        
        script.onerror = () => {
            console.error(`Failed to load script: ${src}`);
            doc.head.removeChild(script);
        };
    } catch (error) {
        console.error('Error loading script:', error);
    }
}