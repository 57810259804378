import { ThemeOptions } from '@mui/material';
import React, { useCallback, useState, SyntheticEvent } from 'react';
import { AccordionVariant1 } from './AccordionVariant1';

export type AccordionsSectionProps = {
    options: Array<{
        icon?: string;
        label?: string;
        component?: JSX.Element;
    }>;
    themeOptions?: ThemeOptions;
    onChanges?: (index: number) => void;
};

export function AccordionsSection({ options, themeOptions }: AccordionsSectionProps) {
    const [expanded, setExpanded] = useState<number | undefined>(undefined);

    const handleChange = useCallback(
        (index: number) => (event: SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? index : undefined);
        },
        [],
    );

    return (
        <>
            {React.Children.toArray(
                options.map((option, index) => {
                    const isExpanded = expanded === index;

                    return (
                        <AccordionVariant1
                            {...option}
                            themeOptions={themeOptions}
                            index={index}
                            handleChange={handleChange}
                            isExpanded={isExpanded}
                        />
                    );
                }),
            )}
        </>
    );
}
