import SortIcon from '@cfra-nextgen-frontend/shared/src/assets/icons/sort.svg';
import { TypographyStyle25 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import { Box, ButtonBase, ButtonBaseProps, SxProps } from '@mui/material';
import { FiltersBadge, FiltersBadgeProps } from './FiltersBadge';

type FiltersButtonProps = ButtonBaseProps & {
    externalChipItems?: FiltersBadgeProps['externalChipItems'];
    filterButtonTextStyle?: SxProps;
};

export function FiltersButton({ externalChipItems, onClick, filterButtonTextStyle }: FiltersButtonProps) {
    return (
        <ButtonBase
            onClick={onClick}
            sx={{
                padding: '3px',
                margin: '0px',
                position: 'relative',
                top: '0px',
                borderRadius: '5px',
                '&:hover': {
                    backgroundColor: '#0000000A',
                },
            }}>
            <FiltersBadge externalChipItems={externalChipItems}>
                <Box
                    component='img'
                    sx={{
                        width: '17px',
                        marginTop: '-2px',
                    }}
                    src={SortIcon}
                    height='auto'
                />
                <TypographyStyle25
                    sx={{
                        paddingLeft: '3px',
                        ...filterButtonTextStyle
                    }}>
                    Filters
                </TypographyStyle25>
            </FiltersBadge>
        </ButtonBase>
    );
}
