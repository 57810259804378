import { cloneDeep } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { OnNewPreferencesSetEvent } from '../components/UserPreferences/PreferencesUpdater';
import {
    getPreferencesStorageKey,
    UserInitialPreferencesContext,
} from '../components/UserPreferences/UserInitialPreferencesContext';
import {
    AgGridPreferences,
    AgGridPreferencesActions,
    GenericActionType,
    GenericEachTypeExtension,
    GenericSelector,
    IdsAndSelectionsPreferences,
    IdsAndSelectionsPreferencesActions,
    PreferencesBase,
    PreferencesEachElement,
    PreferenceType,
    UserPreferences,
} from '../types/userPreferences';
import { ApplicationType, getDateTimeUtcNow } from '../utils';
import { useLocalStorage } from './useLocalStorage';

export type UsePreferenceType = {
    // nullable to be able to turn off the hook when needed
    applicationType?: ApplicationType;
};

export type SetUserPreferences = (t: PreferenceType, v: any) => void;

function getIndexOfPreference<T extends PreferencesBase = PreferencesBase>({
    userPreferences,
    selector,
}: {
    userPreferences: T;
    selector: Pick<NonNullable<T['each']>[number], keyof GenericSelector>;
}): number {
    const index = userPreferences?.each?.findIndex((preference) => {
        const dependencies: Array<boolean> = [
            preference.pageName === selector.pageName,
            preference.location === selector.location,
            preference.featureName === selector.featureName,
        ];

        return dependencies.every((d) => d);
    });

    return typeof index === 'number' ? index : -1;
}

function basePreferencesHandler<PreferencesType extends PreferencesBase>({
    newUserPreferences,
    setUserPreferences,
    handleAction,
}: {
    newUserPreferences: PreferencesType;
    setUserPreferences: Dispatch<SetStateAction<PreferencesType>>;
    handleAction: ({
        newPreference,
        prevUserPreference,
    }: {
        newPreference: NonNullable<PreferencesType['each']>[number];
        prevUserPreference: NonNullable<PreferencesType['each']>[number];
    }) => NonNullable<PreferencesType['each']>[number];
}): void {
    setUserPreferences((prevUserPreferencesOriginal) => {
        const prevUserPreferences = cloneDeep(prevUserPreferencesOriginal);

        newUserPreferences?.each?.forEach((newPreference) => {
            const matchingOldPreferenceIndex = getIndexOfPreference({
                userPreferences: prevUserPreferences,
                selector: newPreference,
            });

            if (matchingOldPreferenceIndex === -1) {
                const { action, ...valueToSet } = newPreference;
                prevUserPreferences.each = prevUserPreferences.each || [];
                prevUserPreferences.each.push({
                    ...valueToSet,
                    // we don't need to set action values in the storage
                    action: [],
                    createdDate: getDateTimeUtcNow(),
                    updateDate: getDateTimeUtcNow(),
                    version: 1,
                });
                return;
            }

            prevUserPreferences.each![matchingOldPreferenceIndex] = {
                ...handleAction({
                    newPreference,
                    prevUserPreference: prevUserPreferences.each![matchingOldPreferenceIndex],
                }),
                updateDate: getDateTimeUtcNow(),
            };
        });

        return prevUserPreferences;
    });
}

export function useUserPreferences(props?: UsePreferenceType) {
    const { userInitialPreferences } = useContext(UserInitialPreferencesContext);

    // if props are not provided, then the hook should not be used
    const applicationType = useMemo(() => props?.applicationType, [props]);

    const preferencesStorageProps = useMemo(
        () => [getPreferencesStorageKey(applicationType), {} as UserPreferences] as const,
        [applicationType],
    );

    const [, setUserPreferencesLocalStorage, getUserPreferencesLocalStorage] = useLocalStorage<UserPreferences>(
        ...preferencesStorageProps,
    );

    const shouldUpdateEventRef = useRef<boolean>(false);

    useEffect(() => {
        // don't fire the event if the hook is not used
        if (!shouldUpdateEventRef.current || !applicationType) {
            return;
        }

        const userPreferences = getUserPreferencesLocalStorage();

        // fire event to be captured by send new preferences to API
        const event = new CustomEvent(OnNewPreferencesSetEvent, { detail: { newValue: userPreferences } });
        window?.dispatchEvent(event);

        shouldUpdateEventRef.current = false;
    }, [getUserPreferencesLocalStorage, applicationType]);

    const _setUserPreferences: ReturnType<typeof useLocalStorage<UserPreferences>>[1] = useCallback(
        (value, refreshPrevStorageValue) => {
            setUserPreferencesLocalStorage((previousValue) => {
                const newValue = typeof value === 'function' ? value(previousValue) : value;

                if (JSON.stringify(previousValue) !== JSON.stringify(newValue)) {
                    shouldUpdateEventRef.current = true;
                    return newValue;
                }

                return previousValue;
            }, refreshPrevStorageValue);
        },
        [setUserPreferencesLocalStorage],
    );

    const getUserPreferences = useCallback(
        <T extends PreferencesEachElement>({
            preferenceType,
            selector,
        }: {
            preferenceType: PreferenceType;
            selector: Pick<T, keyof GenericSelector>;
        }): T | undefined => {
            let result: T | undefined = undefined;
            let indexOfPreference: number = -1;

            const userPreferences = getUserPreferencesLocalStorage();

            switch (preferenceType) {
                case PreferenceType.IdsAndSelectionsPreferences:
                    indexOfPreference = getIndexOfPreference({
                        userPreferences: userPreferences?.preferences?.idsAndSelections || {},
                        selector,
                    });
                    break;
                case PreferenceType.AgGridPreferences:
                    indexOfPreference = getIndexOfPreference({
                        userPreferences: userPreferences?.preferences?.agGrid || {},
                        selector,
                    });
                    break;
            }

            if (indexOfPreference === -1) {
                return undefined;
            }

            // userPreferences!.preferences!.idsAndSelections!.each! this is 100% true at this point, I use ! here just to make TS happy
            switch (preferenceType) {
                case PreferenceType.IdsAndSelectionsPreferences:
                    result = userPreferences!.preferences!.idsAndSelections!.each![indexOfPreference] as T;
                    break;
                case PreferenceType.AgGridPreferences:
                    result = userPreferences!.preferences!.agGrid!.each![indexOfPreference] as T;
                    break;
            }

            return result;
        },
        // keep userInitialPreferences in the dependencies to update userPreferences when userInitialPreferences loaded
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [getUserPreferencesLocalStorage, userInitialPreferences],
    );

    const setUserPreferences = useCallback(
        <ActionType extends GenericActionType, EachTypeExtension extends GenericEachTypeExtension<ActionType>>(
            preferenceType: PreferenceType,
            newUserPreferencesGeneric:
                | PreferencesBase<ActionType, EachTypeExtension>
                | PreferencesEachElement<ActionType, EachTypeExtension>,
        ) => {
            const newUserPreferences: PreferencesBase<ActionType, EachTypeExtension> = Object.keys(
                newUserPreferencesGeneric,
            ).includes('each')
                ? (newUserPreferencesGeneric as PreferencesBase<ActionType, EachTypeExtension>)
                : ({ each: [newUserPreferencesGeneric] } as PreferencesBase<ActionType, EachTypeExtension>);

            switch (preferenceType) {
                case PreferenceType.IdsAndSelectionsPreferences:
                    basePreferencesHandler<IdsAndSelectionsPreferences>({
                        newUserPreferences,
                        setUserPreferences: ((
                            setStateFunction: (previousValue: PreferencesBase) => PreferencesBase,
                        ) => {
                            _setUserPreferences((prevUserPreferencesOriginal) => {
                                const prevUserPreferences = cloneDeep(prevUserPreferencesOriginal);

                                const previousIdsAndSelections =
                                    prevUserPreferences.preferences?.idsAndSelections || {};
                                return {
                                    ...prevUserPreferences,
                                    preferences: {
                                        ...prevUserPreferences.preferences,
                                        idsAndSelections: setStateFunction(previousIdsAndSelections),
                                    },
                                };
                            }, true);
                        }) as Dispatch<SetStateAction<PreferencesBase>>,
                        handleAction: ({ newPreference, prevUserPreference: prevUserPreferenceOriginal }) => {
                            const prevUserPreference = cloneDeep(prevUserPreferenceOriginal);

                            if (!Array.isArray(newPreference.action)) {
                                newPreference.action = [newPreference.action];
                            }

                            newPreference.action.forEach((action) => {
                                switch (action) {
                                    case IdsAndSelectionsPreferencesActions.SetLastViewedWatchlistId:
                                        if (!('lastViewedWatchlistId' in newPreference)) {
                                            throw new Error(
                                                'lastViewedWatchlistId value is required for SetLastViewedWatchlistId action',
                                            );
                                        }

                                        prevUserPreference.lastViewedWatchlistId = newPreference.lastViewedWatchlistId;
                                        break;
                                    case IdsAndSelectionsPreferencesActions.SetResearchTypeIds:
                                        if (!('researchTypeIds' in newPreference)) {
                                            throw new Error(
                                                'lastViewedWatchlistId value is required for SetLastViewedWatchlistId action',
                                            );
                                        }

                                        prevUserPreference.researchTypeIds = newPreference.researchTypeIds;
                                        break;
                                    case IdsAndSelectionsPreferencesActions.SetGicsSectors:
                                        if (!('gicsSectors' in newPreference)) {
                                            throw new Error(
                                                'lastViewedWatchlistId value is required for SetLastViewedWatchlistId action',
                                            );
                                        }

                                        prevUserPreference.gicsSectors = newPreference.gicsSectors;
                                        break;
                                }
                            });

                            return prevUserPreference;
                        },
                    });
                    break;

                case PreferenceType.AgGridPreferences:
                    basePreferencesHandler<AgGridPreferences>({
                        newUserPreferences,
                        setUserPreferences: ((
                            setStateFunction: (previousValue: PreferencesBase) => PreferencesBase,
                        ) => {
                            _setUserPreferences((prevUserPreferencesOriginal) => {
                                const prevUserPreferences = cloneDeep(prevUserPreferencesOriginal);

                                const previousAgGrid = prevUserPreferences.preferences?.agGrid || {};
                                return {
                                    ...prevUserPreferences,
                                    preferences: {
                                        ...prevUserPreferences.preferences,
                                        agGrid: setStateFunction(previousAgGrid),
                                    },
                                };
                            }, true);
                        }) as Dispatch<SetStateAction<PreferencesBase>>,
                        handleAction: ({ newPreference, prevUserPreference: prevUserPreferenceOriginal }) => {
                            const prevUserPreference = cloneDeep(prevUserPreferenceOriginal);

                            switch (newPreference.action) {
                                case AgGridPreferencesActions.SetSortModel:
                                    if (!newPreference.columnsSort) {
                                        throw new Error(
                                            'newPreference.columnsSort is required for SetSortModel action',
                                        );
                                    }

                                    prevUserPreference.columnsSort = newPreference.columnsSort;
                                    break;
                                case AgGridPreferencesActions.SetColumnsVisibility:
                                    if (!newPreference.columnsVisibility) {
                                        throw new Error(
                                            'newPreference.columnsVisibility is required for SetColumnsVisibility action',
                                        );
                                    }

                                    prevUserPreference.columnsVisibility = newPreference.columnsVisibility;
                                    break;
                                case AgGridPreferencesActions.SetColumnsWidth:
                                    if (!newPreference.columnsWidths) {
                                        throw new Error(
                                            'newPreference.columnsWidths is required for SetColumnsWidth action',
                                        );
                                    }

                                    prevUserPreference.columnsWidths = newPreference.columnsWidths;
                                    break;
                                case AgGridPreferencesActions.SetColumnsOrder:
                                    if (!newPreference.columnsOrder) {
                                        throw new Error(
                                            'newPreference.columnsOrder is required for SetColumnsOrder action',
                                        );
                                    }

                                    prevUserPreference.columnsOrder = newPreference.columnsOrder;
                                    break;
                                case AgGridPreferencesActions.ResetAllPreferences:
                                    prevUserPreference.columnsSort = undefined;
                                    prevUserPreference.columnsVisibility = undefined;
                                    prevUserPreference.columnsWidths = undefined;
                                    prevUserPreference.columnsOrder = undefined;
                                    break;
                            }

                            return prevUserPreference;
                        },
                    });
                    break;
            }
        },
        [_setUserPreferences],
    );

    // don't return anything if the hook is not used
    return applicationType ? { getUserPreferences, setUserPreferences } : {};
}
