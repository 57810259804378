import {
    determineGetFiltersData,
    determineGetScreenerDataSSR,
    determineSendMultipleRequest
} from '@cfra-nextgen-frontend/shared/src/components/Screener/api/screener';
import { ApiNames } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { getData, UseData, UseMultipleData } from 'utils/api';

const apiName = ApiNames.Research;

export const getFiltersData = determineGetFiltersData({ UseData, apiName, filtersPath: 'screener-filters' });
export const getScreenerDataSSR = determineGetScreenerDataSSR(getData, apiName, '');
export const sendMultipleRequest = determineSendMultipleRequest({ UseMultipleData });
