import { useEffect, useState } from 'react';

type ResultObject = Record<string, any> | any[];

export function evaluateMediaQueries(
    data?: ResultObject,
    matches: Record<string, boolean> = {},
): ResultObject | undefined {
    if (!data || typeof data !== 'object') {
        return data;
    }

    const result: ResultObject = Array.isArray(data) ? [] : {};

    for (const [key, value] of Object.entries(data)) {
        if (key.startsWith && key.startsWith('@media')) {
            const mediaQuery = key.slice(7).trim();
            if (!matches[mediaQuery]) {
                matches[mediaQuery] = window.matchMedia(mediaQuery).matches;
            }

            if (matches[mediaQuery]) {
                const nestedResult = evaluateMediaQueries(value, matches);
                if (Array.isArray(result)) {
                    result.push(...(Array.isArray(nestedResult) ? nestedResult : [nestedResult]));
                } else {
                    Object.assign(result, nestedResult);
                }
            }
        } else if (Array.isArray(value)) {
            (result as Record<string, any>)[key] = value.map((item) => evaluateMediaQueries(item, matches));
        } else if (typeof value === 'object' && value !== null) {
            (result as Record<string, any>)[key] = evaluateMediaQueries(value, matches);
        } else {
            (result as Record<string, any>)[key] = value;
        }
    }

    return result;
}

// Collect all media queries
const getMediaQueries = (data: any): Set<string> => {
    const mediaQueries = new Set<string>();

    const _getMediaQueries = (data: any) => {
        if (Array.isArray(data)) {
            // Traverse nested arrays
            for (const item of data) {
                _getMediaQueries(item);
            }
        } else if (typeof data === 'object' && data !== null) {
            // Traverse object keys
            for (const key of Object.keys(data)) {
                if (key.startsWith('@media')) {
                    mediaQueries.add(key.slice(7).trim());
                    // Recursively check for nested media queries within the media query value
                    _getMediaQueries(data[key]);
                } else {
                    _getMediaQueries(data[key]);
                }
            }
        }
    };

    _getMediaQueries(data);

    return mediaQueries;
};

type UseMediaDataTransformerProps<T> = {
    inputData?: T;
};

export function useMediaDataTransformer<T extends ResultObject = ResultObject>({
    inputData,
}: UseMediaDataTransformerProps<T>): T | undefined {
    const [transformedData, setTransformedData] = useState<T>(() => evaluateMediaQueries(inputData) as T);

    useEffect(() => {
        const mediaQueries = getMediaQueries(inputData);

        // Set up listeners for each media query
        const mediaQueryLists = Array.from(mediaQueries).map((query) => {
            const mql = window.matchMedia(query);
            mql.addEventListener('change', updateTransformedData);
            return mql;
        });

        function updateTransformedData() {
            setTransformedData(evaluateMediaQueries(inputData) as T);
        }

        // Initial evaluation
        updateTransformedData();

        // Cleanup listeners on unmount
        return () => {
            mediaQueryLists.forEach((mql) => mql.removeEventListener('change', updateTransformedData));
        };
    }, [inputData]);

    return transformedData;
}
