import { ColDef } from 'ag-grid-community';
import { UserPreferencesProps } from '../components/UserPreferences/UserPreferences';
import { RequireAtLeastOne } from './common';

export type GeneralPreferencesConfiguration<T extends 'singleSelector' | readonly string[] = 'singleSelector'> = {
    useUserPreferencesProps: UserPreferencesProps['useUserPreferencesProps'];
    selectorConfiguration: T extends 'singleSelector'
        ? SingleSelectorConfiguration
        : MultipleSelectorsConfiguration<T[number]>;
};

export type SingleSelectorConfiguration = {
    selector: GenericSelector;
};

export type MultipleSelectorsConfiguration<ConfigurationName extends string> = Record<
    ConfigurationName,
    SingleSelectorConfiguration
>;

type SelectorBase = {
    pageName?: string;
    location?: string;
    featureName?: string;
    view?: string;
};

export type GenericSelector<T extends SelectorBase = SelectorBase> = RequireAtLeastOne<T, keyof T>;

export type GenericActionType = NonNullable<string>;

type PreferencesEachElementBase<ActionType extends GenericActionType> = {
    // to use in the hook internally only
    createdDate?: string;
    // to use in the hook internally only
    updateDate?: string;
    // to use in the hook internally only
    version?: number;
    // not for saving in the preferences storage, but to pass and use in the hook
    action: ActionType | Array<ActionType>;
} & GenericSelector;

export type GenericEachTypeExtension<ActionType extends GenericActionType> = Omit<
    Record<string, any>,
    keyof PreferencesEachElementBase<ActionType>
>;

export type PreferencesEachElement<
    ActionType extends GenericActionType = GenericActionType,
    EachTypeExtension extends GenericEachTypeExtension<ActionType> = GenericEachTypeExtension<ActionType>,
> = PreferencesEachElementBase<GenericActionType> & EachTypeExtension;

export type PreferencesBase<
    ActionType extends GenericActionType = GenericActionType,
    EachTypeExtension extends GenericEachTypeExtension<ActionType> = GenericEachTypeExtension<ActionType>,
> = {
    all?: {
        /* placeholder for common props if we will need them in future */
    };
    each?: Array<PreferencesEachElement<ActionType, EachTypeExtension>>;
};

type ExtractEachType<T extends PreferencesBase> = NonNullable<T['each']>[number];

export const AgGridPreferencesActions: Record<string, string> = {
    SetSortModel: 'setSortModel',
    SetColumnsVisibility: 'setColumnsVisibility',
    SetColumnsWidth: 'setColumnsWidth',
    SetColumnsOrder: 'setColumnsOrder',
    ResetAllPreferences: 'resetAllPreferences',
};

type BasicColumnsState = {
    colDef: Partial<ColDef>;
};

export type AgGridEachTypeExtension = {
    // placeholder for columnsState?: Array<BasicColumnsState>;, maybe we will need this in future
    columnsSort?: Array<BasicColumnsState>;
    columnsVisibility?: Array<BasicColumnsState>;
    columnsWidths?: Array<BasicColumnsState>;
    columnsOrder?: Array<BasicColumnsState>;
};

export type AgGridPreferences = PreferencesBase<keyof typeof AgGridPreferencesActions, AgGridEachTypeExtension>;

export type AgGridPreferencesEachElement = ExtractEachType<AgGridPreferences>;

export enum IdsAndSelectionsPreferencesActions {
    SetLastViewedWatchlistId = 'setLastViewedWatchlistId',
    SetResearchTypeIds = 'setResearchTypeIds',
    SetGicsSectors = 'setGicsSectors',
}

export type IdsAndSelectionsTypeExtension = {
    lastViewedWatchlistId?: number;
    researchTypeIds?: Array<number>;
    gicsSectors?: Array<string>;
};

export type IdsAndSelectionsPreferences = PreferencesBase<
    keyof typeof IdsAndSelectionsPreferencesActions,
    IdsAndSelectionsTypeExtension
>;

export type IdsAndSelectionsPreferencesEachElement = ExtractEachType<IdsAndSelectionsPreferences>;

export type UserPreferences = {
    preferences?: {
        agGrid?: AgGridPreferences;
        idsAndSelections?: IdsAndSelectionsPreferences;
    };
};

export enum PreferenceType {
    AgGridPreferences = 'AgGridPreferences',
    IdsAndSelectionsPreferences = 'IdsAndSelectionsPreferences',
}
