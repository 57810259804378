import { Box, SxProps } from '@mui/material';
import React from 'react';
import { TitleWithLink, TitleWithLinkProps } from '../TitleWithLink';

export type Results2ColumnsProps = {
    leftOptions: React.ReactNode;
    rightOptions: React.ReactNode;
    inputValue: string;
    topLinkParams?: {
        text: string;
        pathname: string;
    };
    onClickOutsideCallback?: () => void;
    title: string;
    titleWithLinkProps?: Partial<TitleWithLinkProps>;
    optionsContainerSx?: SxProps;
};

export function Results2Columns({
    leftOptions,
    rightOptions,
    inputValue,
    topLinkParams,
    onClickOutsideCallback,
    title,
    titleWithLinkProps,
    optionsContainerSx,
}: Results2ColumnsProps) {
    return (
        <>
            <TitleWithLink
                title={title}
                linkProps={{
                    linkText: topLinkParams?.text || '',
                    onClick: () => onClickOutsideCallback?.(),
                    state: { searchTerm: inputValue },
                    to: {
                        pathname: topLinkParams?.pathname || '',
                    },
                }}
                {...titleWithLinkProps}
            />
            <Box sx={{ display: 'flex', width: '100%', height: 'calc(100% - 45px)', ...optionsContainerSx }}>
                {/* Left sub block */}
                {leftOptions}
                {/* Right sub block */}
                {rightOptions}
            </Box>
        </>
    );
}
