import { ReactComponent as ArrowRight } from '@cfra-nextgen-frontend/shared/src/assets/icons/arrow-right.svg';
import { ReactComponent as ArrowRight3 } from '@cfra-nextgen-frontend/shared/src/assets/icons/arrow-right-3.svg';
import { CustomSvgIcon } from '@cfra-nextgen-frontend/shared/src/components/Icon/CustomSvgIcon';

export const expandIcon = (
    <CustomSvgIcon
        component={ArrowRight}
        viewBox='0 0 7 12'
        className='customExpandIcon'
        sx={{
            width: '7px',
            fill: '#999999',
            transition: 'transform 0.1s ease-in-out',
        }}
    />
);

export const expandIconStyle3 = (
    <CustomSvgIcon
        component={ArrowRight3}
        viewBox='0 0 16 16'
        className='customExpandIcon'
        sx={{
            width: '16px',
            fill: '#999999',
            transition: 'transform 0.1s ease-in-out',
        }}
    />
);
