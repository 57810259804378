import { StyledAvatar } from '@cfra-nextgen-frontend/shared/src/components/Avatar/Avatar';
import { TypographyStyle26 } from '@cfra-nextgen-frontend/shared/src/components/Typography/StyledTypography';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    ButtonBase,
    createTheme,
    styled,
    ThemeOptions,
    ThemeProvider,
} from '@mui/material';
import { SyntheticEvent } from 'react';
import { expandIcon } from './shared';

const accordionsSectionDefaultThemeOptions = {
    components: {
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    padding: '0px',
                    '&.Mui-expanded': {
                        backgroundColor: '#E3F2FD',
                        '& .customExpandIcon': {
                            transform: 'rotate(90deg)',
                        },
                    },
                },
                content: {
                    margin: '0px',
                },
            },
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: 'unset',
                    borderRadius: '0px !important',
                    '&::before': {
                        opacity: 0,
                    },
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: '0px',
                    margin: '17px 22px',
                    paddingLeft: '22px',
                    borderLeft: '1px dotted #DDDDDD',
                },
            },
        },
    },
};

const FullSizeFlexBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '100%',
}));

export type AccordionProps = {
    icon?: string;
    label?: string;
    component?: JSX.Element;
    themeOptions?: ThemeOptions;
    isExpanded?: boolean;
    handleChange: (index: number) => (event: SyntheticEvent, isExpanded: boolean) => void;
    index: number;
};

export function AccordionVariant1({
    index,
    icon,
    label,
    component,
    themeOptions,
    isExpanded,
    handleChange,
}: AccordionProps) {
    return (
        <ThemeProvider theme={createTheme(accordionsSectionDefaultThemeOptions, themeOptions || {})}>
            <Accordion
                key={index}
                expanded={isExpanded}
                onChange={(event: SyntheticEvent<Element, Event>, expanded: boolean) => {
                    handleChange(index)(event, expanded);
                }}
                disableGutters>
                <AccordionSummary aria-controls={`${index}bh-content`} id={`${index}bh-header`}>
                    <ButtonBase
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                            height: '48px',
                            padding: '0px 14px',
                        }}>
                        {icon && (
                            <FullSizeFlexBox
                                sx={{
                                    width: 'unset',
                                    paddingRight: '16px',
                                }}>
                                <StyledAvatar src={icon} />
                            </FullSizeFlexBox>
                        )}
                        <FullSizeFlexBox
                            sx={{
                                justifyContent: 'space-between',
                                borderBottom: '1px dotted #DDDDDD',
                            }}>
                            {label && (
                                <TypographyStyle26
                                    sx={{
                                        paddingTop: '4px',
                                    }}>
                                    {label}
                                </TypographyStyle26>
                            )}
                            {
                                <FullSizeFlexBox sx={{ paddingRight: '8px', width: 'unset' }}>
                                    {expandIcon}
                                </FullSizeFlexBox>
                            }
                        </FullSizeFlexBox>
                    </ButtonBase>
                </AccordionSummary>
                {/* We want the component to always load so that the useImperative handle reference is never null */}
                <AccordionDetails>{component}</AccordionDetails>
            </Accordion>
        </ThemeProvider>
    );
}
