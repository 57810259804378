import { breakpointsThemeOptions } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { createTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';

export const commonCustomBreakpointsThemeOptions = deepmerge(breakpointsThemeOptions, {
    breakpoints: {
        values: {
            md: 1000,
            sm: 768,
        },
    },
});

export const commonCustomBreakpointsTheme = createTheme(commonCustomBreakpointsThemeOptions);
