import { useUserPreferences } from '@cfra-nextgen-frontend/shared/src/hooks/useUserPreferences';
import {
    AgGridEachTypeExtension,
    AgGridPreferencesActions,
    GenericSelector,
    PreferencesEachElement,
    PreferenceType,
} from '@cfra-nextgen-frontend/shared/src/types/userPreferences';
import { ColDef, GridApi } from 'ag-grid-community';
import { cloneDeep } from 'lodash';
import { ColumnDef } from '../../AgGrid/types';

export function saveUserSortModel({
    api,
    setUserPreferences,
    selector,
    columnsSort,
}: {
    api?: GridApi;
    setUserPreferences?: ReturnType<typeof useUserPreferences>['setUserPreferences'];
    selector?: GenericSelector;
    columnsSort?: AgGridEachTypeExtension['columnsSort'];
}) {
    if (!selector || !setUserPreferences || (!api && !columnsSort)) {
        return;
    }

    let _columnsSort: AgGridEachTypeExtension['columnsSort'] = [];

    if (columnsSort) {
        _columnsSort = columnsSort;
    }

    if (api) {
        _columnsSort = api.getColumnState().map((columnState) => ({
            colDef: {
                colId: columnState.colId,
                sort: columnState.sort,
            },
        }));
    }

    setUserPreferences<keyof typeof AgGridPreferencesActions, AgGridEachTypeExtension>(
        PreferenceType.AgGridPreferences,
        {
            ...selector,
            action: AgGridPreferencesActions.SetSortModel,
            columnsSort: _columnsSort,
        },
    );
}

export function saveUserColumnsOrder({
    api,
    setUserPreferences,
    selector,
}: {
    api: GridApi;
    setUserPreferences: ReturnType<typeof useUserPreferences>['setUserPreferences'];
    selector: GenericSelector;
}) {
    if (!selector || !setUserPreferences || !api) {
        return;
    }

    const columnsOrder = api.getColumnState().map((columnState) => ({
        colDef: {
            colId: columnState.colId,
        },
    }));

    setUserPreferences<keyof typeof AgGridPreferencesActions, AgGridEachTypeExtension>(
        PreferenceType.AgGridPreferences,
        {
            ...selector,
            action: AgGridPreferencesActions.SetColumnsOrder,
            columnsOrder,
        },
    );
}

export function saveUserColumnsVisibility({
    api,
    setUserPreferences,
    selector,
}: {
    api: GridApi;
    setUserPreferences: ReturnType<typeof useUserPreferences>['setUserPreferences'];
    selector: GenericSelector;
}) {
    if (!selector || !setUserPreferences || !api) {
        return;
    }

    const columnsVisibility = api.getColumnState().map((columnState) => ({
        colDef: {
            colId: columnState.colId,
            hide: columnState.hide || undefined,
        },
    }));

    setUserPreferences<keyof typeof AgGridPreferencesActions, AgGridEachTypeExtension>(
        PreferenceType.AgGridPreferences,
        {
            ...selector,
            action: AgGridPreferencesActions.SetColumnsVisibility,
            columnsVisibility,
        },
    );
}

export function saveUserColumnsWidth({
    api,
    setUserPreferences,
    selector,
}: {
    api: GridApi;
    setUserPreferences: ReturnType<typeof useUserPreferences>['setUserPreferences'];
    selector: GenericSelector;
}) {
    if (!selector || !setUserPreferences || !api) {
        return;
    }

    const columnsWidths = api.getColumnState().map((columnState) => ({
        colDef: {
            colId: columnState.colId,
            width: columnState.width || undefined,
        },
    }));

    setUserPreferences<keyof typeof AgGridPreferencesActions, AgGridEachTypeExtension>(
        PreferenceType.AgGridPreferences,
        {
            ...selector,
            action: AgGridPreferencesActions.SetColumnsWidth,
            columnsWidths,
        },
    );
}

export function getColumnId(colDef: Partial<ColDef>): string {
    return colDef.colId || colDef.field || '';
}

export function getUserColumnDefs({
    initialColumnDefs,
    userPreferences,
    userPreferencesToApply = {
        columnSorting: true,
        columnsWidths: true,
        columnsOrder: true,
        columnsVisibility: true,
    },
    firstColumnHeaderClassesToMove = ['no-left-padding', 'horizontal-padding-14'],
}: {
    initialColumnDefs: ColumnDef[];
    userPreferences: PreferencesEachElement<string, AgGridEachTypeExtension>;
    userPreferencesToApply?: {
        columnsWidths: boolean;
        columnSorting: boolean;

        columnsOrder: boolean;
        columnsVisibility: boolean;
    };
    firstColumnHeaderClassesToMove?: Array<string>;
}): ColumnDef[] {
    let resultColumnDefs = cloneDeep(initialColumnDefs);

    resultColumnDefs = resultColumnDefs.map((initialColumnDef) => {
        // set user column visibility
        const userColumnVisibilityDef = userPreferences.columnsVisibility?.find(
            (userColumnDef) => userColumnDef.colDef.colId === getColumnId(initialColumnDef),
        );

        if (userColumnVisibilityDef) {
            initialColumnDef.hide = userColumnVisibilityDef.colDef.hide;
        }

        // set user column width
        const userColumnWidthDef = userPreferences.columnsWidths?.find(
            (userColumnDef) => userColumnDef.colDef.colId === getColumnId(initialColumnDef),
        );

        if (userColumnWidthDef) {
            initialColumnDef.width = userColumnWidthDef.colDef.width;
            // set maxWidth to enable column resizing, usually this is doing columns autosize,
            // but we shouldn't use columns autosize if use user columns widths
            // TODO: it is moved inside ag grid
            // initialColumnDef.maxWidth = 9999;
        }

        return initialColumnDef;
    });

    // set user sorting
    if (userPreferencesToApply.columnSorting && userPreferences.columnsSort) {
        const userSortedColumn = userPreferences.columnsSort.find((userColumnDef) =>
            ['asc', 'desc'].includes(String(userColumnDef.colDef.sort)),
        );

        resultColumnDefs = resultColumnDefs.map((columnDef) => {
            if (userSortedColumn && userSortedColumn.colDef.colId === getColumnId(columnDef)) {
                columnDef.sort = userSortedColumn.colDef.sort;
                return columnDef;
            }

            // unapply previous sorting
            return {
                ...columnDef,
                sort: null,
            };
        });
    }

    // set user columns order
    if (userPreferencesToApply.columnsOrder && userPreferences.columnsOrder) {
        const orderDefs = userPreferences.columnsOrder.map((columnDef) => columnDef.colDef);
        resultColumnDefs.sort((aColumnDef, bColumnDef) => {
            return (
                orderDefs.findIndex((columnDef) => getColumnId(columnDef) === getColumnId(aColumnDef)) -
                orderDefs.findIndex((columnDef) => getColumnId(columnDef) === getColumnId(bColumnDef))
            );
        });

        // move header classes from old first column to the new first column
        const initialFirstColumnHeaderClass = getHeaderClassFrom(initialColumnDefs[0]);

        if (
            initialFirstColumnHeaderClass &&
            hasCommonElements(initialFirstColumnHeaderClass, firstColumnHeaderClassesToMove)
        ) {
            resultColumnDefs[0].headerClass = [
                ...getHeaderClassFrom(resultColumnDefs[0]),
                ...initialFirstColumnHeaderClass.filter((headerClass) =>
                    firstColumnHeaderClassesToMove.includes(headerClass),
                ),
            ];

            const oldFirstColumnNewIndex = resultColumnDefs.findIndex(
                (columnDef) => getColumnId(columnDef) === getColumnId(initialColumnDefs[0]),
            );

            if (oldFirstColumnNewIndex > 0) {
                resultColumnDefs[oldFirstColumnNewIndex].headerClass = getHeaderClassFrom(
                    resultColumnDefs[oldFirstColumnNewIndex],
                ).filter((headerClass) => !firstColumnHeaderClassesToMove.includes(headerClass));

                if (
                    Array.isArray(resultColumnDefs[oldFirstColumnNewIndex].headerClass) &&
                    resultColumnDefs[oldFirstColumnNewIndex]?.headerClass?.length === 0
                ) {
                    resultColumnDefs[oldFirstColumnNewIndex].headerClass = undefined;
                }
            }
        }
    }

    return resultColumnDefs;
}

function getHeaderClassFrom(columnDef?: ColumnDef): Array<string> {
    if (!columnDef || !columnDef?.headerClass) {
        return [];
    }

    if (Array.isArray(columnDef.headerClass)) {
        return columnDef.headerClass;
    }

    return [columnDef.headerClass];
}

function hasCommonElements(array1: Array<string>, array2: Array<string>): boolean {
    return array1.some((element) => array2.includes(element));
}
