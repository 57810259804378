import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

type UseInViewScrollDownProps = {
    containerRef?: RefObject<HTMLDivElement>;
    elementRef: RefObject<HTMLDivElement>;
    callbackRef: React.RefObject<() => void>;
    thresholdInPxRef: React.RefObject<number>;
};

export function useInViewScrollDown({
    callbackRef,
    containerRef,
    elementRef,
    thresholdInPxRef,
}: UseInViewScrollDownProps) {
    // This hook is better then import { useInView } from 'react-intersection-observer',
    // due to doesn't have unnecessary re-renders.
    // It is used when you need to trigger a callback on scroll one time when the element is in view.
    // The callback will not be called again until the element is out of view and then in view again
    // OR until the unblock callback is called.
    // The in view state calculates based on the container's bottom coordinates and the element's bottom coordinates on scroll.

    const [state, setState] = useState<{
        inView: boolean;
        triggerReRender: boolean; // we need to switch it to trigger re-render
    }>();
    const [resubscribeEventsDependency, _setResubscribeEvents] = useState<boolean>(false);

    const isCallbackBlocked = useRef<boolean>(false);

    const resubscribeEvents = useCallback(() => [_setResubscribeEvents((previousValue) => !previousValue)], []);

    const getContainerHeight = useCallback(() => {
        const containerRefRectHeight = containerRef?.current?.getBoundingClientRect().height;

        if (containerRefRectHeight) {
            return containerRefRectHeight;
        }

        if (!elementRef.current) {
            return undefined;
        }

        // take the height from the top of the element up to the end of the viewport if the container is not provided
        return window.innerHeight - elementRef.current.getBoundingClientRect().top;
    }, [containerRef, elementRef]);

    // check if the element height is less than the container height
    const isElementLessThanContainer = useCallback(() => {
        if (!elementRef.current) {
            return undefined;
        }

        const elementHeight = elementRef.current.getBoundingClientRect().height;
        const containerHeight = getContainerHeight();

        if (!containerHeight) {
            return undefined;
        }

        return elementHeight < containerHeight;
    }, [elementRef, getContainerHeight]);

    const getContainerBottom = useCallback(() => {
        const containerRefRectBottom = containerRef?.current?.getBoundingClientRect().bottom;

        // take the bottom of the window if the container is not provided
        const containerRectBottom =
            typeof containerRefRectBottom === 'number' ? containerRefRectBottom : window.innerHeight;

        return containerRectBottom;
    }, [containerRef]);

    useEffect(() => {
        // unblock the callback if the element is out of view
        if (!state?.inView && isCallbackBlocked.current) {
            isCallbackBlocked.current = false;
            return;
        }

        // call the callback if the element is in view and the callback is not blocked
        if (state?.inView && !isCallbackBlocked.current) {
            isCallbackBlocked.current = true;
            callbackRef.current?.();
            return;
        }
        
        // for the case when the element is less than the container and the callback is blocked
        if (state?.inView && isCallbackBlocked.current && isElementLessThanContainer()) {
            callbackRef.current?.();
            return;
        }
    }, [callbackRef, state, isElementLessThanContainer]);

    const calculateInView = useCallback(() => {
        if (!elementRef.current || typeof thresholdInPxRef.current !== 'number') {
            return;
        }

        const elementBoundingClientRect = elementRef?.current.getBoundingClientRect();
        const elementRectBottom =
            elementBoundingClientRect.top + elementRef?.current.scrollHeight - elementRef?.current.scrollTop; // count also overflowed part of the element

        setState((previousValue) => {
            if (typeof thresholdInPxRef.current !== 'number') {
                return previousValue; // just for typescript, this shouldn't happen
            }

            const newValue = elementRectBottom !== 0 ? // it would not be visible.
                Math.round(elementRectBottom) <= Math.round(getContainerBottom()) + thresholdInPxRef.current : 
                false;

            // handle the case when the element is less than the container and the element already was in view
            // so we need to simulate the case if the element is out of view
            if (newValue && previousValue?.inView === newValue && isElementLessThanContainer()) {
                return {
                    inView: previousValue?.inView,
                    triggerReRender: !previousValue?.triggerReRender, // we need to trigger re-render in this case
                };
            }

            return {
                inView: newValue,
                triggerReRender: Boolean(previousValue?.triggerReRender),
            };
        });
        // it is necessary to have only ref objects in the dependencies array
        // to avoid unnecessary removal / addition scroll event listeners
    }, [elementRef, thresholdInPxRef, isElementLessThanContainer, getContainerBottom]);

    useEffect(() => {
        // debounce the event handler to avoid unnecessary calculations when user scrolls or resizes the window
        const eventHandler = debounce(() => {
            calculateInView()
        }, 200)

        const elementContainer = elementRef?.current;

        if (containerRef === undefined) {
            window.addEventListener('scroll', eventHandler);
            window.addEventListener('resize', eventHandler);
        } else {
            elementContainer?.addEventListener('scroll', eventHandler);
        }

        return () => {
            if (containerRef === undefined) {
                window.removeEventListener('scroll', eventHandler);
                window.removeEventListener('resize', eventHandler);
            } else {
                elementContainer?.removeEventListener('scroll', eventHandler);
            }

            eventHandler.cancel();
        };
    }, [calculateInView, containerRef, elementRef, resubscribeEventsDependency]);

    const unblockAndCalculateInView = useCallback(() => {
        if (!isCallbackBlocked.current) {
            return;
        }

        isCallbackBlocked.current = false;
        calculateInView();
    }, [calculateInView]);

    return { calculateInView, unblockAndCalculateInView, resubscribeEvents };
}
