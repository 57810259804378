import { Item } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { cloneDeep } from 'lodash';
import { useEffect, useRef, useState } from 'react';

type UseVisibleItemsProps = {
    options: Array<Item>;
    optionsToOverride?: Array<Item>;
    customSortOrder?: Array<number | string>;
    itemWidth?: number;
    enableSort?: boolean;
    afterMoreButtonComponentWidth?: number;
    strategy?: Strategy;
    gap?: number;
    moreButtonWidth?: number;
    enabled?: boolean;
    optionsLengths?: Array<number>;
};

export enum Strategy {
    AllItemsSameSize = 'allItemsSameSize',
    AllItemsDifferentSize = 'allItemsDifferentSize',
}

export function useVisibleItems({
    options,
    optionsToOverride,
    customSortOrder,
    itemWidth = 200,
    enableSort = true,
    afterMoreButtonComponentWidth = 0,
    strategy = Strategy.AllItemsSameSize,
    gap = 8,
    moreButtonWidth = 56,
    enabled = true,
    optionsLengths,
}: UseVisibleItemsProps) {
    const [visibleItems, setVisibleItems] = useState<UseVisibleItemsProps['options']>([]);
    const [showPillsAmount, setShowPillsAmount] = useState<number>(0);
    const [moreItems, setMoreItems] = useState<Array<any>>([]);

    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const updatePillsVisibility = (strategy: Strategy) => {
            if (!containerRef.current) {
                return;
            }

            if (strategy === Strategy.AllItemsSameSize) {
                const containerWidth = containerRef.current.offsetWidth;
                const maxVisiblePills = Math.floor(
                    (containerWidth - moreButtonWidth - afterMoreButtonComponentWidth) / (itemWidth + gap),
                );

                if (maxVisiblePills !== showPillsAmount) {
                    setShowPillsAmount(maxVisiblePills);
                }
                return;
            }

            if (strategy === Strategy.AllItemsDifferentSize) {
                if (!optionsLengths) {
                    throw new Error('optionsLengths is required when using Strategy.AllItemsDifferentSize');
                }

                // check if optionsLengths has the same length as options
                if (optionsLengths.length !== options.length) {
                    throw new Error('optionsLengths should have the same length as options');
                }

                let containerWidth = containerRef.current.offsetWidth - afterMoreButtonComponentWidth;

                const isMoreButtonVisible = showPillsAmount < options.length;
                if (isMoreButtonVisible) {
                    containerWidth -= moreButtonWidth;
                }

                let currentWidth = 0;
                let visiblePills = 0;

                

                for (let i = 0; i < optionsLengths.length; i++) {
                    const length = optionsLengths[i];
                    const isLastItem = i === optionsLengths.length - 1;
                    const itemWidthWithGap = isLastItem ? length : length + gap;

                    if (currentWidth + itemWidthWithGap <= containerWidth) {
                        currentWidth += itemWidthWithGap;
                        visiblePills++;
                    } else {
                        // Check if more button can fit
                        if (currentWidth + moreButtonWidth <= containerWidth) {
                            currentWidth += moreButtonWidth;
                        }
                        break;
                    }
                }

                if (visiblePills !== showPillsAmount) {
                    setShowPillsAmount(visiblePills);
                }
            }
        };

        const updatePillsVisibilityLocal = () => updatePillsVisibility(strategy);

        const currentContainer = containerRef.current;
        const resizeObserver = new ResizeObserver(updatePillsVisibilityLocal);

        if (currentContainer && enabled) {
            resizeObserver.observe(currentContainer);
            updatePillsVisibilityLocal();
        }

        const onUnmount = () => {
            resizeObserver.disconnect();
        };

        if (!enabled) {
            onUnmount();
        }

        return () => {
            onUnmount();
        };
    }, [
        moreButtonWidth,
        itemWidth,
        gap,
        showPillsAmount,
        afterMoreButtonComponentWidth,
        strategy,
        enabled,
        optionsLengths,
        options,
    ]);

    useEffect(() => {
        optionsToOverride?.forEach((optionToOverride) => {
            const index = options.findIndex((option) => option.key === optionToOverride.key);
            if (index !== -1) {
                options[index] = optionToOverride;
            }
        });

        const sortedItems = (function () {
            if (!customSortOrder) {
                cloneDeep(options).sort((a: any, b: any) => a.value.localeCompare(b.value));
            }
            const customSortOrderConverted = customSortOrder?.map((item) => String(item)) || [];
            return options.sort(function (a, b) {
                // Pass a function to the sort that takes 2 elements to compare
                // Subtract indexes, If element `a` comes first in the array, the returned value will be negative, resulting in it being sorted before `b`, and vice versa.
                return (
                    (customSortOrderConverted?.indexOf(String(a.key)) || 0) -
                    (customSortOrderConverted?.indexOf(String(b.key)) || 0)
                );
            });
        })();

        const newItems = enableSort ? sortedItems : options;

        const newVisibleItems = newItems.slice(0, showPillsAmount);
        const newMoreItems = newItems.slice(showPillsAmount);

        if (
            JSON.stringify(newVisibleItems) !== JSON.stringify(visibleItems) ||
            JSON.stringify(newMoreItems) !== JSON.stringify(moreItems)
        ) {
            setVisibleItems(newVisibleItems);
            setMoreItems(newMoreItems);
        }
    }, [showPillsAmount, options, visibleItems, moreItems, customSortOrder, optionsToOverride, enableSort]);

    return {
        containerRef,
        visibleItems,
        showPillsAmount,
        moreItems,
    };
}
