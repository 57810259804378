import { Navigate, Route, Routes } from 'react-router-dom';
import { BespokeEdgeHome } from '../components/BespokeEdgeHome';
import { BespokeServicesHome } from '../components/BespokeServicesHome';
import { BespokeAdmin } from '../components/BespokeAdmin';
import { PageWithComponentInHeader } from '@cfra-nextgen-frontend/shared/src/components/PageLayouts/PageWithComponentInHeader/PageWithComponentInHeader';
import { horizontalPaddingInSu, maxPageWidthInPx } from 'utils/lookAndFeel';

export function BespokeRoutes() {
    return (
        <Routes>
            <Route path='/*' element={<Navigate to='./edge' />} />
            <Route path='/edge' element={<BespokeEdgeHome />} />
            <Route
                path='/services'
                element={
                    <PageWithComponentInHeader
                        pageContainerSx={{
                            maxWidth: '100%',
                            paddingLeft: horizontalPaddingInSu,
                            paddingRight: horizontalPaddingInSu,
                            display: 'flex',
                            justifyContent: 'center',
                            minHeight: 'unset',
                            '@media (max-width: 768.5px)': {
                                paddingLeft: '14px',
                                paddingRight: '14px',
                            },
                        }}
                        tabContainerSx={{
                            paddingTop: horizontalPaddingInSu,
                            paddingBottom: horizontalPaddingInSu,
                            paddingLeft: 0,
                            paddingRight: 0,
                            maxWidth: maxPageWidthInPx,
                            boxSizing: 'content-box',
                            backgroundColor: 'unset',
                            width: '100%',
                            '@media (max-width: 768.5px)': {
                                paddingTop: '14px',
                                paddingBottom: '14px',
                            },
                        }}
                        outletComponent={<BespokeServicesHome />}
                    />
                }
            />
            <Route path='/admin' element={<BespokeAdmin />} />
        </Routes>
    );
}
