import { ReactComponent as ScrollIcon } from '@cfra-nextgen-frontend/shared/src/assets/icons/arrow-right.svg';
import { Box, ButtonBase, SxProps } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shadowLeftStylesVariant1, shadowRightStylesVariant1 } from '../../utils/shadows';
import { CustomSvgIcon } from '../Icon/CustomSvgIcon';

type HorizontalCarouselProps = {
    children: Array<React.ReactNode>;
};

function StyledTabScrollButton(props: {
    onClick: () => void;
    iconSx?: SxProps;
    containerSx?: SxProps;
    direction: 'left' | 'right';
}) {
    const isLeftDirection = props.direction !== 'right';

    return (
        <ButtonBase
            onClick={props.onClick}
            sx={{
                backgroundColor: '#FFFFFF',
                width: '36px',
                height: 'calc(100% + 2px)',
                opacity: 1,
                position: 'absolute',
                zIndex: 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                ...(isLeftDirection
                    ? { left: -1, ...shadowRightStylesVariant1 }
                    : { right: -1, ...shadowLeftStylesVariant1 }),
                ...props.containerSx,
            }}>
            <CustomSvgIcon
                component={ScrollIcon}
                viewBox='0 0 7 12'
                sx={{
                    transform: isLeftDirection ? 'rotate(180deg)' : 'none',
                    width: '6.55px',
                    height: '12px',
                    fill: '#5A5A5A',
                    ...props.iconSx,
                }}
            />
        </ButtonBase>
    );
}

function handleScroll(container: HTMLDivElement | null, direction: 'left' | 'right') {
    if (!container) {
        return;
    }

    const itemWidth = container.firstElementChild?.clientWidth || 0; // Get the width of the first item
    const scrollAmount = itemWidth + 16; // Add gap between items (16px in this case)
    const newScrollPosition =
        direction === 'left'
            ? Math.max(0, container.scrollLeft - scrollAmount)
            : Math.min(container.scrollWidth - container.offsetWidth, container.scrollLeft + scrollAmount);

    container.scrollTo({ left: newScrollPosition, behavior: 'smooth' });
}

export const HorizontalCarousel = function ({ children }: HorizontalCarouselProps) {
    const scrollContainerRef = useRef<HTMLDivElement>(null); // Ref to the scrolling container
    const [isOverflowing, setIsOverflowing] = useState(false); // Track overflow status
    const [atLeftEnd, setAtLeftEnd] = useState(true); // Track if at the left end
    const [atRightEnd, setAtRightEnd] = useState(false); // Track if at the right end

    // Check if the content overflows the container
    const checkOverflow = useCallback(() => {
        const container = scrollContainerRef.current;
        if (!container) {
            return;
        }

        const isContentOverflowing = container.scrollWidth > container.offsetWidth;
        setIsOverflowing(isContentOverflowing);
        setAtLeftEnd(container.scrollLeft === 0);
        setAtRightEnd(container.scrollLeft + container.offsetWidth >= container.scrollWidth);
    }, []);

    // Monitor container overflow whenever the window or content changes
    useEffect(() => {
        checkOverflow();
        window.addEventListener('resize', checkOverflow);

        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, [checkOverflow]);

    const resultJSX = useMemo(() => {
        return (
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1,
                    position: 'relative',
                }}>
                {/* Left Arrow */}
                <StyledTabScrollButton
                    direction='left'
                    onClick={() => handleScroll(scrollContainerRef.current, 'left')}
                    containerSx={{
                        visibility: isOverflowing && !atLeftEnd ? 'visible' : 'hidden',
                    }}
                />

                {/* Scrollable Content */}
                <Box
                    ref={scrollContainerRef}
                    sx={{
                        display: 'flex',
                        overflowX: 'auto',
                        maxWidth: '100%',
                        scrollSnapType: 'x mandatory',
                        '& > div': {
                            scrollSnapAlign: 'start',
                            flexShrink: 0,
                        },
                        gap: 2,
                        flexGrow: 1,
                        // Hide the scrollbar
                        scrollbarWidth: 'none', // Firefox
                        '&::-webkit-scrollbar': {
                            display: 'none', // Chrome, Safari
                        },
                        paddingTop: '10px',
                        marginTop: '-10px',
                    }}
                    onScroll={checkOverflow}
                >
                    {children}
                </Box>

                {/* Right Arrow */}
                <StyledTabScrollButton
                    direction='right'
                    onClick={() => handleScroll(scrollContainerRef.current, 'right')}
                    containerSx={{
                        visibility: isOverflowing && !atRightEnd ? 'visible' : 'hidden',
                    }}
                />
            </Box>
        );
    }, [children, isOverflowing, atLeftEnd, atRightEnd, checkOverflow]);

    return resultJSX;
};
