import { Grid } from '@cfra-nextgen-frontend/shared/src/components/layout/Grid';
import { ScoresInfoBanner } from 'features/scores/components/ScoresInfoBanner';
import { horizontalPaddingInSu } from 'utils/lookAndFeel';
import { ScoresCardWithContexts } from './scoresCard/ScoresCardWithContexts';
import { ThemeProvider, useMediaQuery } from '@mui/material';
import { homePageTheme } from 'components/themes/theme';
import { customBreakpointTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { ExpandableAccordion } from '@cfra-nextgen-frontend/shared/src/components/ExpandableAccordion';

const contentScores = {
    topics: [
        {
            title: 'Understanding CFRA Scores',
            content: <ScoresInfoBanner />,
        },
    ],
};

function getTitleBoxStyles(isExpanded: boolean) {
    return {
        backgroundColor: isExpanded ? '#002B5A' : '#fff',
        marginBottom: '0px',
    };
}
function getCointentBoxStyles() {
    return {
        marginBottom: '0px',
        paddingLeft: '0px',
    };
}
export function ScoresHome() {
    const isMobileVariant = useMediaQuery(customBreakpointTheme.breakpoints.down('sm'));

    return (
        <ThemeProvider theme={homePageTheme}>
            <Grid container spacing={horizontalPaddingInSu} paddingX={{ xs: '15px', sm: '0' }}>
                {!isMobileVariant && (
                    <Grid item xs={12} sx={{ marginTop: '-8px' }}>
                        <ExpandableAccordion
                            content={contentScores}
                            titleStyle={{ fontSize: '40px' }}
                            contentWrapperSx={{ marginTop: '0px' }}
                            getTitleBoxStyles={getTitleBoxStyles}
                            getCointentBoxStyles={getCointentBoxStyles}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <ScoresCardWithContexts />
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
