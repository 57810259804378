import { ControlledAccordions } from '@cfra-nextgen-frontend/shared/src/components/ControlledAccordions';
import { ThemeOptions } from '@mui/material';
import { useMemo } from 'react';
import { StyledAvatar } from '../Avatar/Avatar';
import { expandIconStyle3 } from '../ControlledAccordions/shared';
import { ETFMenu, ETFMenuProps } from './ETFMenu';
import { accordionBlackBorder, accordionBlueLinks } from './theme';

export type MenuItem = {
    name: string;
    icon?: string;
    onClick: () => void;
    subItems?: Array<Omit<MenuItem, 'subItems'>>;
};

type MenuItemsProps = {
    themeOptions?: ThemeOptions;
    menuItemsConfig: Array<MenuItem>;
};

function MenuItems({
    themeOptions,
    menuItemsConfig,
    handleClose,
}: MenuItemsProps & Parameters<NonNullable<ETFMenuProps['MenuItemsComponent']>>[0]) {
    return (
        <ControlledAccordions
            themeOptions={themeOptions}
            expandIcon={expandIconStyle3}
            config={menuItemsConfig.map((menuItemConfig) => ({
                name: menuItemConfig.name,
                onClickCallback:
                    (menuItemConfig.subItems?.length || 0) > 0
                        ? undefined
                        : () => {
                              handleClose();
                              menuItemConfig.onClick();
                          },
                items: menuItemConfig.subItems?.map((subItem) => ({
                    name: subItem.name,
                    onClickCallback: () => {
                        handleClose();
                        subItem.onClick();
                    },
                })),
                icon: <StyledAvatar src={menuItemConfig.icon} />,
            }))}
        />
    );
}

type AccordionsMenuProps = MenuItemsProps & {
    openComponent: React.ReactNode;
    variant: 'blueLinks' | 'blackBorderLinks';
};

export function AccordionsMenu({ openComponent, variant, ...props }: AccordionsMenuProps) {
    const themeOptions = useMemo(
        () => (variant === 'blueLinks' ? accordionBlueLinks : accordionBlackBorder),
        [variant],
    );

    return (
        <ETFMenu
            keepOpen
            useClickableBox
            themeOptions={themeOptions}
            MenuItemsComponent={({ handleClose }) => (
                <MenuItems {...props} themeOptions={themeOptions} handleClose={handleClose} />
            )}>
            {openComponent}
        </ETFMenu>
    );
}
